var subspeciality = {
   "english": [
      {
         "label_en": "Critical care medicine",
         "label_de": "Intensivmedizin",
         "label_nl": "Intensive care geneeskunde",
         "label_rs": "Медицина критических состояний",
         "label_sp": "Medicina de Terapia Intensiva",
         "label_pt": "medicina intensiva",
         "label_sw": "Dawa muhimu ya utunzaji",
         "label_ch": "重症监护医学",
         "label_fr": "Médecine des soins intensifs",
         "label_ar": "العناية المركزة",
         "value": "Critical_care_medicine"
      },
      {
         "label_en": "Hospice and palliative care",
         "label_de": "Hospice and palliative care",
         "label_nl": "Hospice en palliatieve zorg",
         "label_rs": "Хоспис и паллиативная помощь",
         "label_sp": "Cuidados paliativos",
         "label_pt": "Hospício e cuidados paliativos",
         "label_sw": "Hospitali na huduma ya kupendeza",
         "label_ch": "临终关怀和姑息治疗",
         "label_fr": "Hospices et soins palliatifs",
         "label_ar": "رعاية المحتضرين والرعاية المخففة للآلام",
         "value": "Hospice_and_palliative_care"
      },
      {
         "label_en": "Pain medicine",
         "label_de": "Schmerzmittel",
         "label_nl": "Pijnmedicatie",
         "label_rs": "Медицина болевых синдромов",
         "label_sp": "Medicina para el dolor",
         "label_pt": "Analgésico",
         "label_sw": "Dawa ya maumivu",
         "label_ch": "止痛药",
         "label_fr": "Médicaments antidouleur",
         "label_ar": "طب الألم",
         "value": "Pain_medicine"
      },
      {
         "label_en": "Pediatric anesthesiology",
         "label_de": "Pädiatrische Anästhesiologie",
         "label_nl": "Pediatric anesthesiologie",
         "label_rs": "Детская анестезиология",
         "label_sp": "anestesiología pediátrica",
         "label_pt": "anestesiologia pediátrica",
         "label_sw": "Anesthesiolojia ya watoto",
         "label_ch": "儿科麻醉学",
         "label_fr": "Anesthésiologie pédiatrique",
         "label_ar": "قسم تخدير الأطفال",
         "value": "Pediatric_anesthesiology"
      },
      {
         "label_en": "Sleep medicine",
         "label_de": "Schlafmedizin",
         "label_nl": "Sleep Medicine",
         "label_rs": "Медицина сна",
         "label_sp": "La medicina del sueño",
         "label_pt": "medicina do sono",
         "label_sw": "Dawa ya kulala",
         "label_ch": "睡眠药",
         "label_fr": "Médecine du sommeil",
         "label_ar": "طب النوم",
         "value": "Sleep_medicine"
      },
      {
         "label_en": "Dermatopathology",
         "label_de": "Dermatopathologie",
         "label_nl": "Dermatopathology",
         "label_rs": "Дерматопатология",
         "label_sp": "dermatopatología",
         "label_pt": "Dermatopathology",
         "label_sw": "Utibabu wa ngozi",
         "label_ch": "皮肤病理学",
         "label_fr": "Dermatopathologie",
         "label_ar": "الباثولوجيا الجلدية",
         "value": "Dermatopathology"
      },
      {
         "label_en": "Pediatric dermatology",
         "label_de": "Pädiatrische Dermatologie",
         "label_nl": "Kinderdermatologie",
         "label_rs": "Детская дерматология",
         "label_sp": "dermatología pediátrica",
         "label_pt": "dermatologia pediátrica",
         "label_sw": "Utibabu wa ngozi ya watoto",
         "label_ch": "儿科皮肤科",
         "label_fr": "Dermatologie pédiatrique",
         "label_ar": "طب جلدية الأطفال",
         "value": "Pediatric_dermatology"
      },
      {
         "label_en": "Procedural dermatology",
         "label_de": "Verfahrensdermatologie",
         "label_nl": "procedurele dermatologie",
         "label_rs": "Процедурная дерматология",
         "label_sp": "dermatología intervencionista",
         "label_pt": "dermatologia processual",
         "label_sw": "Utabibu wa ngozi kiutaratibu",
         "label_ch": "程序性皮肤科",
         "label_fr": "Dermatologie procédurale",
         "label_ar": "طب جراحات الجلد",
         "value": "Procedural_dermatology"
      },
      {
         "label_en": "Abdominal radiology",
         "label_de": "Bauchradiologie",
         "label_nl": "abdominale radiologie",
         "label_rs": "Абдоминальная радиология",
         "label_sp": "radiología abdominal",
         "label_pt": "radiologia abdominal",
         "label_sw": "Radiolojia ya tumbo",
         "label_ch": "腹部放射科",
         "label_fr": "Radiologie abdominale",
         "label_ar": "قسم أشعة الباطنة",
         "value": "Abdominal_radiology"
      },
      {
         "label_en": "Breast imaging",
         "label_de": "Brustbildgebung",
         "label_nl": "Breast imaging",
         "label_rs": "Лучевые исследования молочной железы",
         "label_sp": "imagen de mama",
         "label_pt": "imagens da mama",
         "label_sw": "Picha ya matiti",
         "label_ch": "乳房成像",
         "label_fr": "Imagerie mammaire",
         "label_ar": "تصوير الثدي",
         "value": "Breast_imaging"
      },
      {
         "label_en": "Cardiothoracic radiology",
         "label_de": "Kardiothorakale Radiologie",
         "label_nl": "cardiothoracale radiologie",
         "label_rs": "Кардиоторакальная радиология",
         "label_sp": "radiología cardiotorácica",
         "label_pt": "radiologia cardiotorácica",
         "label_sw": "Radiolojia ya moyo",
         "label_ch": "心胸放射科",
         "label_fr": "Radiologie cardiothoracique",
         "label_ar": "أشعة قلب وصدر",
         "value": "Cardiothoracic_radiology"
      },
      {
         "label_en": "Cardiovascular radiology",
         "label_de": "Herz-Kreislauf-Radiologie",
         "label_nl": "cardiovasculaire radiologie",
         "label_rs": "Сердечно-сосудистая Радиология",
         "label_sp": "radiología cardiovascular",
         "label_pt": "radiologia cardiovascular",
         "label_sw": "Radiolojia ya mishipa wa moyo",
         "label_ch": "心血管放射学",
         "label_fr": "Radiologie cardiovasculaire",
         "label_ar": "أشعة قلب وأوعية دموية",
         "value": "Cardiovascular_radiology"
      },
      {
         "label_en": "Chest radiology",
         "label_de": "Brustradiologie",
         "label_nl": "borst radiologie",
         "label_rs": "Радиология грудной клетки",
         "label_sp": "radiología de tórax",
         "label_pt": "peito de radiologia",
         "label_sw": "Radiolojia ya kifua",
         "label_ch": "胸部放射科",
         "label_fr": "Radiologie thoracique",
         "label_ar": "أشعة صدر",
         "value": "Chest_radiology"
      },
      {
         "label_en": "Emergency radiology",
         "label_de": "Notfallradiologie",
         "label_nl": "Emergency radiologie",
         "label_rs": "Экстренная радиология",
         "label_sp": "radiología de emergencia",
         "label_pt": "radiologia de emergência",
         "label_sw": "Radiolojia ya dharura",
         "label_ch": "紧急放射科",
         "label_fr": "Radiologie d'urgence",
         "label_ar": "أشعة الطوارئ",
         "value": "Emergency_radiology"
      },
      {
         "label_en": "Endovascular surgical neuroradiology",
         "label_de": "Endovaskuläre chirurgische Neuroradiologie",
         "label_nl": "Endovasculaire chirurgische neuroradiologie",
         "label_rs": "Эндоваскулярная хирургическая нейрорадиология",
         "label_sp": "neurorradiología quirúrgica endovascular",
         "label_pt": "neurorradiologia cirúrgica endovascular",
         "label_sw": "Neuroradiolojia ya upasuaji wa mishipa",
         "label_ch": "血管内手术神经放射学",
         "label_fr": "Neuroradiologie chirurgicale endovasculaire",
         "label_ar": "جراحة الأوعية الدموية العصبية",
         "value": "Endovascular_surgical_neuroradiology"
      },
      {
         "label_en": "Gastrointestinal radiology",
         "label_de": "Gastrointestinale Radiologie",
         "label_nl": "Gastro-intestinale radiologie",
         "label_rs": "Радиология желудочно-кишечного тракта",
         "label_sp": "radiología gastrointestinal",
         "label_pt": "radiologia gastrointestinal",
         "label_sw": "Radiolojia ya njia ya utumbo",
         "label_ch": "胃肠放射科",
         "label_fr": "Radiologie gastro-intestinale",
         "label_ar": "أشعة الجهاز الهضمي",
         "value": "Gastrointestinal_radiology"
      },
      {
         "label_en": "Genitourinary radiology",
         "label_de": "Urogenitale Radiologie",
         "label_nl": "urogenitale radiologie",
         "label_rs": "Радиология мочеполовой системы",
         "label_sp": "radiología genitourinario",
         "label_pt": "radiologia geniturinário",
         "label_sw": "Radiolojia ya mionzi ya kizazi",
         "label_ch": "泌尿生殖放射科",
         "label_fr": "Radiologie génito-urinaire",
         "label_ar": "أشعة الجهاز البولي التناسلي",
         "value": "Genitourinary_radiology"
      },
      {
         "label_en": "Head and neck radiology",
         "label_de": "Kopf-und Halsradiologie",
         "label_nl": "Hoofd en nek radiologie",
         "label_rs": "Радиология головы и шеи",
         "label_sp": "La cabeza y el cuello de radiología",
         "label_pt": "Cabeça e Pescoço",
         "label_sw": "Radiolojia ya kichwa na shingo",
         "label_ch": "头颈部放射科",
         "label_fr": "Radiologie de la tête et du cou",
         "label_ar": "أشعة الرأس والرقبة",
         "value": "Head_and_neck_radiology"
      },
      {
         "label_en": "Interventional radiology",
         "label_de": "Interventionellen Radiologie",
         "label_nl": "interventionele radiologie",
         "label_rs": "Инвазивная радиология",
         "label_sp": "Radiología intervencional",
         "label_pt": "Radiologia intervencional",
         "label_sw": "Radiolojia ya kuingilia kati",
         "label_ch": "介入放射学",
         "label_fr": "Radiologie interventionnelle",
         "label_ar": "أشعة العمليات الجراحية",
         "value": "Interventional_radiology"
      },
      {
         "label_en": "Musculoskeletal radiology",
         "label_de": "Radiologie des Bewegungsapparates",
         "label_nl": "bewegingsapparaat radiologie",
         "label_rs": "Радиология опорно-двигательного аппарата",
         "label_sp": "radiología musculoesquelética",
         "label_pt": "radiologia músculo-esquelético",
         "label_sw": "Radiolojia ya misuli",
         "label_ch": "肌肉骨骼放射学",
         "label_fr": "Radiologie musculo-squelettique",
         "label_ar": "أشعة الجهاز العضلي الهيكلي",
         "value": "Musculoskeletal_radiology"
      },
      {
         "label_en": "Neuroradiology",
         "label_de": "Neuroradiologie",
         "label_nl": "neuroradiologie",
         "label_rs": "Нейрорадиология",
         "label_sp": "neurorradiología",
         "label_pt": "neurorradiologia",
         "label_sw": "Neuroradiolojia",
         "label_ch": "神经放射学",
         "label_fr": "Neuroradiologie",
         "label_ar": "أشعة الأعصاب",
         "value": "Neuroradiology"
      },
      {
         "label_en": "Nuclear radiology",
         "label_de": "Nukleare Radiologie",
         "label_nl": "Nuclear radiologie",
         "label_rs": "Ядерная радиология",
         "label_sp": "radiología nuclear",
         "label_pt": "radiologia nuclear",
         "label_ch": "核放射学",
         "label_fr": "Radiologie nucléaire",
         "label_ar": "الأشعة النووية",
         "value": "Nuclear_radiology"
      },
      {
         "label_en": "Pediatric radiology",
         "label_de": "Pädiatrische Radiologie",
         "label_nl": "Pediatric radiologie",
         "label_rs": "Детская радиология",
         "label_sp": "radiología pediátrica",
         "label_pt": "radiologia pediátrica",
         "label_sw": "Radiolojia ya watoto",
         "label_ch": "儿科放射科",
         "label_fr": "Radiologie pédiatrique",
         "label_ar": "أشعة الأطفال",
         "value": "Pediatric_radiology"
      },
      {
         "label_en": "Radiation oncology",
         "label_de": "Radioonkologie",
         "label_nl": "Stralings-oncologie",
         "label_rs": "Радиационная онкология",
         "label_sp": "oncología de radiación",
         "label_pt": "Oncologia de Radiação",
         "label_sw": "Oncolojia ya mionzi",
         "label_ch": "放射肿瘤学",
         "label_fr": "Radio-oncologie",
         "label_ar": "علاج الأورام بالإشعاع",
         "value": "Radiation_oncology"
      },
      {
         "label_en": "Vascular and interventional radiology",
         "label_de": "Gefäß-und interventionelle Radiologie",
         "label_nl": "Vasculaire en interventionele radiologie",
         "label_rs": "Сосудистая и интервенционная радиология",
         "label_sp": "Vascular y radiología intervencionista",
         "label_pt": "Vascular e radiologia intervencionista",
         "label_sw": "Radiolojia ya mishipa na ya kuingilia kati",
         "label_ch": "血管和介入放射学",
         "label_fr": "Radiologie vasculaire et interventionnelle",
         "label_ar": "أشعة الأوعية الدموية والتداخلية",
         "value": "Vascular_and_interventional_radiology"
      },
      {
         "label_en": "Anesthesiology critical care medicine",
         "label_de": "Anästhesiologische Intensivmedizin",
         "label_nl": "Anesthesiologie intensive care geneeskunde",
         "label_rs": "Анестезиология реаниматология",
         "label_sp": "Anestesiología medicina de cuidados críticos",
         "label_pt": "Anestesiologia medicina intensiva",
         "label_sw": "Dawa ya utunzaji muhimu ya Anesthesiolojia",
         "label_ch": "麻醉学重症监护医学",
         "label_fr": "Anesthésiologie médecine de soins intensifs",
         "label_ar": "التخدير وطب العناية المركزة",
         "value": "Anesthesiology_critical_care_medicine"
      },
      {
         "label_en": "Emergency medical services",
         "label_de": "Notfallmedizin",
         "label_nl": "Dringende medische diensten",
         "label_rs": "Скорая медицинская помощь",
         "label_sp": "Servicios médicos de emergencia",
         "label_pt": "Serviço de emergencia médica",
         "label_sw": "Huduma za dharura za matibabu",
         "label_ch": "紧急医疗服务",
         "label_fr": "Services médicaux d'urgence",
         "label_ar": "الخدمات الطبية الطارئة",
         "value": "Emergency_medical_services"
      },
      {
         "label_en": "Hospice and palliative medicine",
         "label_de": "Hospiz und Palliativmedizin",
         "label_nl": "Hospice en palliatieve geneeskunde",
         "label_rs": "Хоспис и паллиативная медицина",
         "label_sp": "Hospicio y medicina paliativa",
         "label_pt": "Hospício e medicina paliativa",
         "label_sw": "Hospitali na dawa ya kutuliza",
         "label_ch": "临终关怀和姑息药",
         "label_fr": "Hospice et médecine palliative",
         "label_ar": "رعاية المحتضرين والرعاية المخففة للآلام",
         "value": "Hospice_and_palliative_medicine"
      },
      {
         "label_en": "Internal medicine / Critical care medicine",
         "label_de": "Innere Medizin / Intensivmedizin",
         "label_nl": "Interne geneeskunde / intensive care geneeskunde",
         "label_rs": "Внутренняя медицина/медицина критических состояний",
         "label_sp": "medicina interna / medicina crítica",
         "label_pt": "medicina interna / medicina intensiva",
         "label_sw": "Dawa ya undani / Dawa muhimu ya utunzaji",
         "label_ch": "内科/重症医学",
         "label_fr": "Médecine interne / Médecine des soins intensifs",
         "label_ar": "الباطنة / طب العناية المركزة",
         "value": "Internal_medicine_/_Critical_care_medicine"
      },
      {
         "label_en": "Medical toxicology",
         "label_de": "Medizinische Toxikologie",
         "label_nl": "medische toxicologie",
         "label_rs": "Медицинская токсикология",
         "label_sp": "toxicología médica",
         "label_pt": "toxicologia médica",
         "label_sw": "Matibabu ya sumu",
         "label_ch": "医学毒理学",
         "label_fr": "Toxicologie médicale",
         "label_ar": "علم السموم الطبية",
         "value": "Medical_toxicology"
      },
      {
         "label_en": "Pain medicine",
         "label_de": "Schmerzmittel",
         "label_nl": "Pijnmedicatie",
         "label_rs": "Медицина болевых синдромов",
         "label_sp": "Medicina para el dolor",
         "label_pt": "Analgésico",
         "label_sw": "Dawa ya maumivu",
         "label_ch": "止痛药",
         "label_fr": "Médicaments antidouleur",
         "label_ar": "طب الألم",
         "value": "Pain_medicine"
      },
      {
         "label_en": "Pediatric emergency medicine",
         "label_de": "Pädiatrische Notfallmedizin",
         "label_nl": "Pediatrische spoedeisende geneeskunde",
         "label_rs": "Педиатрическая неотложная медицина",
         "label_sp": "medicina de emergencia pediátrica",
         "label_pt": "medicina de emergência pediátrica",
         "label_sw": "Dawa ya dharura ya watoto",
         "label_ch": "儿科急诊医学",
         "label_fr": "Médecine d'urgence pédiatrique",
         "label_ar": "طب طوارئ الأطفال",
         "value": "Pediatric_emergency_medicine"
      },
      {
         "label_en": "Sports medicine",
         "label_de": "Sportmedizin",
         "label_nl": "sportgeneeskunde",
         "label_rs": "Спортивная медицина",
         "label_sp": "Medicina deportiva",
         "label_pt": "medicina desportiva",
         "label_sw": "Dawa ya michezo",
         "label_ch": "运动医学",
         "label_fr": "Médecine du sport",
         "label_ar": "الطب الرياضي",
         "value": "Sports_medicine"
      },
      {
         "label_en": "Undersea and hyperbaric medicine",
         "label_de": "Unterwasser-und Überdruckmedizin",
         "label_nl": "Undersea en hyperbare geneeskunde",
         "label_rs": "Подводная и гипербарическая медицина",
         "label_sp": "Submarina y la medicina hiperbárica",
         "label_pt": "Submarino e medicina hiperbárica",
         "label_sw": "Dawa ya chini ya maji na hyperbaric",
         "label_ch": "海底和高压医学",
         "label_fr": "Médecine sous-marine et hyperbare",
         "label_ar": "طب قاع البحر والضغط العالي",
         "value": "Undersea_and_hyperbaric_medicine"
      },
      {
         "label_en": "Adolescent medicine",
         "label_de": "Jugendmedizin",
         "label_nl": "adolescent Medicine",
         "label_rs": "Подростковая медицина",
         "label_sp": "medicina adolescente",
         "label_pt": "medicina adolescente",
         "label_sw": "Dawa ya vijana",
         "label_ch": "青少年医学",
         "label_fr": "Médecine des adolescents",
         "label_ar": "طب المراهقين",
         "value": "Adolescent_medicine"
      },
      {
         "label_en": "Geriatric medicine",
         "label_de": "Geriatrie",
         "label_nl": "geriatrische geneeskunde",
         "label_rs": "Гериатрическая медицина",
         "label_sp": "Medicina Geriatrica",
         "label_pt": "Medicina Geriátrica",
         "label_sw": "Dawa ya Geriatric",
         "label_ch": "老年医学",
         "label_fr": "Médecine gériatrique",
         "label_ar": "طب كبار السن",
         "value": "Geriatric_medicine"
      },
      {
         "label_en": "Hospice and palliative medicine",
         "label_de": "Hospiz und Palliativmedizin",
         "label_nl": "Hospice en palliatieve geneeskunde",
         "label_rs": "Хоспис и паллиативная медицина",
         "label_sp": "Hospicio y medicina paliativa",
         "label_pt": "Hospício e medicina paliativa",
         "label_sw": "Hospitali na dawa ya kutuliza",
         "label_ch": "临终关怀和姑息药",
         "label_fr": "Hospice et médecine palliative",
         "label_ar": "رعاية المحتضرين والرعاية المخففة للآلام",
         "value": "Hospice_and_palliative_medicine"
      },
      {
         "label_en": "Pain medicine",
         "label_de": "Schmerzmittel",
         "label_nl": "Pijnmedicatie",
         "label_rs": "Медицина болевых синдромов",
         "label_sp": "Medicina para el dolor",
         "label_pt": "Analgésico",
         "label_sw": "Dawa ya maumivu",
         "label_ch": "止痛药",
         "label_fr": "Médicaments antidouleur",
         "label_ar": "طب الألم",
         "value": "Pain_medicine"
      },
      {
         "label_en": "Sleep medicine",
         "label_de": "Schlafmedizin",
         "label_nl": "Sleep Medicine",
         "label_rs": "Медицина сна",
         "label_sp": "La medicina del sueño",
         "label_pt": "medicina do sono",
         "label_sw": "Dawa ya kulala",
         "label_ch": "睡眠药",
         "label_fr": "Médecine du sommeil",
         "label_ar": "طب النوم",
         "value": "Sleep_medicine"
      },
      {
         "label_en": "Sports medicine",
         "label_de": "Sportmedizin",
         "label_nl": "sportgeneeskunde",
         "label_rs": "Спортивная медицина",
         "label_sp": "Medicina deportiva",
         "label_pt": "medicina desportiva",
         "label_sw": "Dawa ya michezo",
         "label_ch": "运动医学",
         "label_fr": "Médecine du sport",
         "label_ar": "الطب الرياضي",
         "value": "Sports_medicine"
      },
      {
         "label_en": "Advanced heart failure and transplant cardiology",
         "label_de": "Fortgeschrittene Herzinsuffizienz und Transplantationskardiologie",
         "label_nl": "Geavanceerde hartfalen en transplantatie cardiologie",
         "label_rs": "Сердечная недостаточность и трансплантационная кардиология",
         "label_sp": "La insuficiencia cardíaca avanzada y trasplante de cardiología",
         "label_pt": "insuficiência cardíaca avançada e cardiologia transplante",
         "label_sw": "Kushindwa kwa moyo wa hali ya juu na kupandikiza moyo",
         "label_ch": "高级心力衰竭和移植心脏病学",
         "label_fr": "Insuffisance cardiaque avancée et cardiologie de la transplantation",
         "label_ar": "قسم فشل القلب وعمليات زراعة القلب",
         "value": "Advanced_heart_failure_and_transplant_cardiology"
      },
      {
         "label_en": "Cardiovascular disease",
         "label_de": "Kardiologie",
         "label_nl": "Hart-en vaatziekte",
         "label_rs": "Сердечно-сосудистые заболевания",
         "label_sp": "Enfermedad cardiovascular",
         "label_pt": "Doença cardiovascular",
         "label_sw": "Ugonjwa wa moyo na mishipa",
         "label_ch": "心血管疾病",
         "label_fr": "Maladies cardiovasculaires",
         "label_ar": "أمراض القلب والأوعية الدموية",
         "value": "Cardiovascular_disease"
      },
      {
         "label_en": "Clinical cardiac electrophysiology",
         "label_de": "Angiologie",
         "label_nl": "Klinische cardiale elektrofysiologie",
         "label_rs": "Клиническая электрофизиологииясердца",
         "label_sp": "electrofisiología cardíaca clínica",
         "label_pt": "Electrofisiologia Clínica cardíaca",
         "label_sw": "Kliniki ya elektroniki ya moyo",
         "label_ch": "临床心脏电生理",
         "label_fr": "Électrophysiologie cardiaque clinique",
         "label_ar": "الفيسيولوجيا الكهربية للقلب",
         "value": "Clinical_cardiac_electrophysiology"
      },
      {
         "label_en": "Critical care medicine",
         "label_de": "Intensivmedizin",
         "label_nl": "Intensive care geneeskunde",
         "label_rs": "Медицина критических состояний",
         "label_sp": "Medicina de Terapia Intensiva",
         "label_pt": "medicina intensiva",
         "label_sw": "Dawa muhimu ya utunzaji",
         "label_ch": "重症监护医学",
         "label_fr": "Médecine des soins intensifs",
         "label_ar": "العناية المركزة",
         "value": "Critical_care_medicine"
      },
      {
         "label_en": "Endocrinology, diabetes, and metabolism",
         "label_de": "Endokrinologie, Diabetes und Stoffwechsel",
         "label_nl": "Endocrinologie, diabetes, en metabolisme",
         "label_rs": "Эндокринология, диабет и обмен веществ",
         "label_sp": "Endocrinología, diabetes y metabolismo",
         "label_pt": "Endocrinologia, diabetes e metabolismo",
         "label_sw": "Endocrinolojia, ugonjwa wa sukari na kimetaboliki",
         "label_ch": "内分泌、糖尿病和代谢",
         "label_fr": "Endocrinologie, diabète et métabolisme",
         "label_ar": "أمراض الغدد الصماء والسكري والتمثيل الغذائي",
         "value": "Endocrinology,_diabetes,_and_metabolism"
      },
      {
         "label_en": "Gastroenterology",
         "label_de": "Gastroenterologie",
         "label_nl": "Gastroenterology",
         "label_rs": "Гастроэнтерология",
         "label_sp": "Gastroenterología",
         "label_pt": "Gastroenterology",
         "label_sw": "Ugonjwa wa tumbo",
         "label_ch": "胃肠病学",
         "label_fr": "Gastroentérologie",
         "label_ar": "أمراض الجهاز الهضمي والأمعاء",
         "value": "Gastroenterology"
      },
      {
         "label_en": "Geriatric medicine",
         "label_de": "Geriatrie",
         "label_nl": "geriatrische geneeskunde",
         "label_rs": "Гериатрическая медицина",
         "label_sp": "Medicina Geriatrica",
         "label_pt": "Medicina Geriátrica",
         "label_sw": "Dawa ya Geriatric",
         "label_ch": "老年医学",
         "label_fr": "Médecine gériatrique",
         "label_ar": "طب كبار السن",
         "value": "Geriatric_medicine"
      },
      {
         "label_en": "Hematology",
         "label_de": "Hämatologie",
         "label_nl": "Hematologie",
         "label_rs": "Гематология",
         "label_sp": "Hematología",
         "label_pt": "Hematologia",
         "label_sw": "Hematolojia",
         "label_ch": "血液学",
         "label_fr": "Hématologie",
         "label_ar": "قسم أمراض الدم",
         "value": "Hematology"
      },
      {
         "label_en": "Hematology and oncology",
         "label_de": "Hämatologie und Onkologie",
         "label_nl": "Hematologie en oncologie",
         "label_rs": "Гематология и онкология",
         "label_sp": "Hematología y oncología",
         "label_pt": "Hematologia e oncologia",
         "label_sw": "Hematolojia na oncolojia",
         "label_ch": "血液学和肿瘤学",
         "label_fr": "Hématologie et oncologie",
         "label_ar": "أمراض الدم والأورام",
         "value": "Hematology_and_oncology"
      },
      {
         "label_en": "Infectious disease",
         "label_de": "Infektiologie",
         "label_nl": "Besmettelijke ziekte",
         "label_rs": "Инфекционное заболевание",
         "label_sp": "Enfermedad infecciosa",
         "label_pt": "Doença infecciosa",
         "label_sw": "Ugonjwa wa kuambukiza",
         "label_ch": "传染病",
         "label_fr": "Maladies infectieuses",
         "label_ar": "الأمراض المعدية",
         "value": "Infectious_disease"
      },
      {
         "label_en": "Internal medicine",
         "label_de": "Innere Medizin",
         "label_nl": "Inwendig medicijn",
         "label_rs": "ВНУТРЕННЯЯ МЕДИЦИНА",
         "label_sp": "Medicina Interna",
         "label_pt": "Medicina Interna",
         "label_sw": "Dawa ya undani",
         "label_ch": "内科",
         "label_fr": "Internal medicine",
         "label_ar": "الباطنة",
         "value": "Internal_medicine"
      },
      {
         "label_en": "Interventional cardiology",
         "label_de": "Interventionelle Kardiologie",
         "label_nl": "interventionele cardiologie",
         "label_rs": "Инвазивная кардиология",
         "label_sp": "cardiología intervencionista",
         "label_pt": "cardiologia intervencionista",
         "label_sw": "Cardiolojia ya kuingilia kati",
         "label_ch": "介入心脏病学",
         "label_fr": "Cardiologie interventionnelle",
         "label_ar": "جراحة القلب",
         "value": "Interventional_cardiology"
      },
      {
         "label_en": "Nephrology",
         "label_de": "Nephrologie",
         "label_nl": "nefrologie",
         "label_rs": "Нефрология",
         "label_sp": "Nefrología",
         "label_pt": "Nefrologia",
         "label_sw": "Nephrolojia",
         "label_ch": "肾脏病",
         "label_fr": "Néphrologie",
         "label_ar": "أمراض الكلى",
         "value": "Nephrology"
      },
      {
         "label_en": "Oncology",
         "label_de": "Onkologie",
         "label_nl": "Oncology",
         "label_rs": "ОНКОЛОГИЯ",
         "label_sp": "Oncología",
         "label_pt": "Oncologia",
         "label_sw": "Oncolojia",
         "label_ch": "肿瘤学",
         "label_fr": "Oncologie",
         "label_ar": "طب الأورام",
         "value": "Oncology"
      },
      {
         "label_en": "Pediatric internal medicine",
         "label_de": "Pädiatrische Innere Medizin",
         "label_nl": "Pediatric interne geneeskunde",
         "label_rs": "Педиатрическая внутренняя медицина",
         "label_sp": "medicina interna pediátrica",
         "label_pt": "medicina interna pediátrica",
         "label_sw": "Dawa ya ndani ya watoto",
         "label_ch": "小儿内科",
         "label_fr": "Médecine interne pédiatrique",
         "label_ar": "قسم الباطنة للأطفال",
         "value": "Pediatric_internal_medicine"
      },
      {
         "label_en": "Pulmonary disease",
         "label_de": "Pulmonary disease",
         "label_nl": "Longziekte",
         "label_rs": "Легочная болезнь",
         "label_sp": "Enfermedad pulmonar",
         "label_pt": "Doença pulmonar",
         "label_sw": "Ugonjwa wa mapafu",
         "label_ch": "肺部疾病",
         "label_fr": "Maladie pulmonaire",
         "label_ar": "أمراض الصدر",
         "value": "Pulmonary_disease"
      },
      {
         "label_en": "Pulmonary disease and critical care medicine",
         "label_de": "Pneumologie und Intensivmedizin",
         "label_nl": "Longziekte en intensive care geneeskunde",
         "label_rs": "Легочные заболевания и медицина критических состояний",
         "label_sp": "enfermedad pulmonar y medicina de cuidados críticos",
         "label_pt": "doença pulmonar e medicina intensiva",
         "label_sw": "Ugonjwa wa mapafu na dawa ya utunzaji muhimu",
         "label_ch": "肺病和重症监护医学",
         "label_fr": "Maladies pulmonaires et médecine des soins intensifs",
         "label_ar": "أمراض الصدر والعناية المركزة",
         "value": "Pulmonary_disease_and_critical_care_medicine"
      },
      {
         "label_en": "Rheumatology",
         "label_de": "Rheumatologie",
         "label_nl": "reumatologie",
         "label_rs": "Ревматология",
         "label_sp": "Reumatología",
         "label_pt": "Reumatologia",
         "label_sw": "Rheumatolojia",
         "label_ch": "风湿病",
         "label_fr": "Rhumatologie",
         "label_ar": "طب الأمراض الروماتيزمية",
         "value": "Rheumatology"
      },
      {
         "label_en": "Sleep medicine",
         "label_de": "Schlafmedizin",
         "label_nl": "Sleep Medicine",
         "label_rs": "Медицина сна",
         "label_sp": "La medicina del sueño",
         "label_pt": "medicina do sono",
         "label_sw": "Dawa ya kulala",
         "label_ch": "睡眠药",
         "label_fr": "Médecine du sommeil",
         "label_ar": "طب النوم",
         "value": "Sleep_medicine"
      },
      {
         "label_en": "Sports medicine",
         "label_de": "Sportmedizin",
         "label_nl": "sportgeneeskunde",
         "label_rs": "Спортивная медицина",
         "label_sp": "Medicina deportiva",
         "label_pt": "medicina desportiva",
         "label_sw": "Dawa ya michezo",
         "label_ch": "运动医学",
         "label_fr": "Médecine du sport",
         "label_ar": "الطب الرياضي",
         "value": "Sports_medicine"
      },
      {
         "label_en": "Transplant hepatology",
         "label_de": "Transplantationshepatologie",
         "label_nl": "transplantatie hepatologie",
         "label_rs": "Трансплантационная гепатология",
         "label_sp": "hepatología de trasplante",
         "label_pt": "hepatologia transplante",
         "label_sw": "Kupandikiza hepatolojia",
         "label_ch": "移植肝病",
         "label_fr": "Hépatologie de la transplantation",
         "label_ar": "زراعة الكبد",
         "value": "Transplant_hepatology"
      },
      {
         "label_en": "Biochemical genetics",
         "label_de": "Biochemische Genetik",
         "label_nl": "biochemische genetica",
         "label_rs": "Биохимическая генетика",
         "label_sp": "genética bioquímica",
         "label_pt": "genética bioquímica",
         "label_sw": "Maumbile ya biokemikali",
         "label_ch": "生化遗传学",
         "label_fr": "Génétique biochimique",
         "label_ar": "علم وراثة الكيمياء الحيوية",
         "value": "Biochemical_genetics"
      },
      {
         "label_en": "Clinical cytogenetics",
         "label_de": "Klinische Zytogenetik",
         "label_nl": "klinische cytogenetica",
         "label_rs": "Клиническая цитогенетика",
         "label_sp": "citogenética clínica",
         "label_pt": "citogenética clínicos",
         "label_sw": "Kliniki ya cytogenetics",
         "label_ch": "临床细胞遗传学",
         "label_fr": "Cytogénétique clinique",
         "label_ar": "علم وراثة الخلية الإكلينيكي",
         "value": "Clinical_cytogenetics"
      },
      {
         "label_en": "Clinical genetics",
         "label_de": "Klinische Genetik",
         "label_nl": "klinische genetica",
         "label_rs": "Клиническая генетика",
         "label_sp": "genética clínica",
         "label_pt": "genética clínica",
         "label_sw": "Kliniki ya maumbile",
         "label_ch": "临床遗传学",
         "label_fr": "Génétique clinique",
         "label_ar": "علم الوراثة الإكلينيكي",
         "value": "Clinical_genetics"
      },
      {
         "label_en": "Molecular genetic pathology",
         "label_de": "Molekulargenetische Pathologie",
         "label_nl": "Moleculair genetische pathologie",
         "label_rs": "Молекулярно-генетическая патология",
         "label_sp": "patología genética molecular",
         "label_pt": "patologia genética molecular",
         "label_sw": "Patholojia ya maumbile ya Masi",
         "label_ch": "分子遗传病理学",
         "label_fr": "Pathologie génétique moléculaire",
         "label_ar": "علم الأمراض الوراثية الجزيئية",
         "value": "Molecular_genetic_pathology"
      },
      {
         "label_en": "Brain injury medicine",
         "label_de": "Arzneimittel gegen Hirnverletzungen",
         "label_nl": "Hersenletsel geneeskunde",
         "label_rs": "Медицина ЧМТ",
         "label_sp": "medicina lesión cerebral",
         "label_pt": "Cérebro medicina lesão",
         "label_sw": "Dawa ya kuumia ubongo",
         "label_ch": "脑损伤医学",
         "label_fr": "Médecine des lésions cérébrales",
         "label_ar": "طب إصابات الدماغ",
         "value": "Brain_injury_medicine"
      },
      {
         "label_en": "Child neurology",
         "label_de": "Kinderneurologie",
         "label_nl": "Child Neurology",
         "label_rs": "Детская неврология",
         "label_sp": "neurología infantil",
         "label_pt": "neurologia infantil",
         "label_sw": "Mishipa ya watoto",
         "label_ch": "儿童神经病学",
         "label_fr": "Neurologie de l'enfant",
         "label_ar": "الأمراض العصبية للأطفال",
         "value": "Child_neurology"
      },
      {
         "label_en": "Clinical neurophysiology",
         "label_de": "Klinische Neurophysiologie",
         "label_nl": "klinische Neurofysiologie",
         "label_rs": "Клиническая нейрофизиология",
         "label_sp": "neurofisiología clínica",
         "label_pt": "neurofisiologia clínica",
         "label_sw": "Kliniki_ ya neurophysiolojia",
         "label_ch": "临床神经生理学",
         "label_fr": "Neurophysiologie clinique",
         "label_ar": "فيسيولوجيا الأعصاب الإكلينيكي",
         "value": "Clinical_neurophysiology"
      },
      {
         "label_en": "Endovascular surgical neuroradiology",
         "label_de": "Endovaskuläre chirurgische Neuroradiologie",
         "label_nl": "Endovasculaire chirurgische neuroradiologie",
         "label_rs": "Эндоваскулярная хирургическая нейрорадиология",
         "label_sp": "neurorradiología quirúrgica endovascular",
         "label_pt": "neurorradiologia cirúrgica endovascular",
         "label_sw": "Neuroradiolojia ya upasuaji wa mishipa",
         "label_ch": "血管内手术神经放射学",
         "label_fr": "Neuroradiologie chirurgicale endovasculaire",
         "label_ar": "جراحة الأوعية الدموية العصبية",
         "value": "Endovascular_surgical_neuroradiology"
      },
      {
         "label_en": "Hospice and palliative medicine",
         "label_de": "Hospiz und Palliativmedizin",
         "label_nl": "Hospice en palliatieve geneeskunde",
         "label_rs": "Хоспис и паллиативная медицина",
         "label_sp": "Hospicio y medicina paliativa",
         "label_pt": "Hospício e medicina paliativa",
         "label_sw": "Hospitali na dawa ya kutuliza",
         "label_ch": "临终关怀和姑息药",
         "label_fr": "Hospice et médecine palliative",
         "label_ar": "رعاية المحتضرين والرعاية المخففة للآلام",
         "value": "Hospice_and_palliative_medicine"
      },
      {
         "label_en": "Neurodevelopmental disabilities",
         "label_de": "Neuroentwicklungsstörungen",
         "label_nl": "neurologische handicap",
         "label_rs": "Нарушения нервно-психического развития",
         "label_sp": "discapacidades del desarrollo neurológico",
         "label_pt": "deficiência do desenvolvimento neurológico",
         "label_sw": "Ulemavu wa maendeleo ya neva",
         "label_ch": "神经发育障碍",
         "label_fr": "Troubles neurologiques du développement",
         "label_ar": "إعاقات النمو العصبي",
         "value": "Neurodevelopmental_disabilities"
      },
      {
         "label_en": "Neuromuscular medicine",
         "label_de": "Neuromuskuläre Medizin",
         "label_nl": "neuromusculaire geneeskunde",
         "label_rs": "Нервно-мышечная медицина",
         "label_sp": "medicina neuromuscular",
         "label_pt": "medicina neuromuscular",
         "label_sw": "Dawa ya mshipa wa neva",
         "label_ch": "神经肌肉医学",
         "label_fr": "Médecine neuromusculaire",
         "label_ar": "طب الأعصاب والعضلات",
         "value": "Neuromuscular_medicine"
      },
      {
         "label_en": "Pain medicine",
         "label_de": "Schmerzmittel",
         "label_nl": "Pijnmedicatie",
         "label_rs": "Медицина болевых синдромов",
         "label_sp": "Medicina para el dolor",
         "label_pt": "Analgésico",
         "label_sw": "Dawa ya maumivu",
         "label_ch": "止痛药",
         "label_fr": "Médicaments antidouleur",
         "label_ar": "طب الألم",
         "value": "Pain_medicine"
      },
      {
         "label_en": "Sleep medicine",
         "label_de": "Schlafmedizin",
         "label_nl": "Sleep Medicine",
         "label_rs": "Медицина сна",
         "label_sp": "La medicina del sueño",
         "label_pt": "medicina do sono",
         "label_sw": "Dawa ya kulala",
         "label_ch": "睡眠药",
         "label_fr": "Médecine du sommeil",
         "label_ar": "طب النوم",
         "value": "Sleep_medicine"
      },
      {
         "label_en": "Vascular neurology",
         "label_de": "Gefäßneurologie",
         "label_nl": "vasculaire neurologie",
         "label_rs": "Сосудистая неврология",
         "label_sp": "neurología vascular",
         "label_pt": "vascular neurologia",
         "label_sw": "Mishipa ya fahamu",
         "label_ch": "血管神经学",
         "label_fr": "Neurologie vasculaire",
         "label_ar": "طب أعصاب الأوعية الدموية",
         "value": "Vascular_neurology"
      },
      {
         "label_en": "Female pelvic medicine and reconstructive surgery",
         "label_de": "Weibliche Beckenmedizin und rekonstruktive Chirurgie",
         "label_nl": "Vrouwelijk bekken geneeskunde en reconstructieve chirurgie",
         "label_rs": "Женская тазовая медицина и реконструктивная хирургия",
         "label_sp": "medicina pélvica femenina y cirugía reconstructiva",
         "label_pt": "medicina pélvica feminina e cirurgia reconstrutiva",
         "label_sw": "Dawa ya kiuno ya kike na upasuaji wa ujenzi",
         "label_ch": "女性骨盆医学和重建手术",
         "label_fr": "Médecine pelvienne féminine et chirurgie reconstructive",
         "label_ar": "طب حوض المرأة والجراحات الترميمية",
         "value": "Female_pelvic_medicine_and_reconstructive_surgery"
      },
      {
         "label_en": "Gynecologic oncology",
         "label_de": "Gynäkologische Onkologie",
         "label_nl": "gynaecologische oncologie",
         "label_rs": "Гинекологическая онкология",
         "label_sp": "Oncología Ginecológica",
         "label_pt": "oncologia ginecológica",
         "label_sw": "Oncolojia ya Gynecologic",
         "label_ch": "妇科肿瘤",
         "label_fr": "Oncologie gynécologique",
         "label_ar": "طب الأورام النسائية",
         "value": "Gynecologic_oncology"
      },
      {
         "label_en": "Maternal-fetal medicine",
         "label_de": "Mütterlich-fetale Medizin",
         "label_nl": "Maternale-foetale geneeskunde",
         "label_rs": "Материнско-фетальная медицина",
         "label_sp": "medicina materno-fetal",
         "label_pt": "medicina materno-fetal",
         "label_sw": "Dawa ya fetasi ya mama",
         "label_ch": "母胎医学",
         "label_fr": "Médecine materno-fœtale",
         "label_ar": "طب الأم والجنين",
         "value": "Maternal-fetal_medicine"
      },
      {
         "label_en": "Reproductive endocrinologists and infertility",
         "label_de": "Fortpflanzungsendokrinologie und Unfruchtbarkeit",
         "label_nl": "Reproductive endocrinologen en onvruchtbaarheid",
         "label_rs": "Репродуктивная эндокринология и бесплодие",
         "label_sp": "endocrinólogos reproductivos y la infertilidad",
         "label_pt": "endocrinologistas reprodutivos e infertilidade",
         "label_sw": "Wataalam wa endocrinolojia ya uzazi na utasa",
         "label_ch": "生殖内分泌学和不孕不育",
         "label_fr": "Endocrinologues de la reproduction et infertilité",
         "label_ar": "أخصائي الغدد الصماء التناسلية والعقم",
         "value": "Reproductive_endocrinologists_and_infertility"
      },
      {
         "label_en": "Anterior segment/cornea ophthalmology",
         "label_de": "Ophtalmologie des vorderen Augensegments / der Hornhaut",
         "label_nl": "Voorste segment / cornea oogheelkunde",
         "label_rs": "Офтальмология роговицы",
         "label_sp": "Segmento anterior / oftalmología córnea",
         "label_pt": "Segmento anterior / oftalmologia córnea",
         "label_sw": "Sehemu ya mbele / ophthalmolojia ya koni",
         "label_ch": "前段/角膜眼科",
         "label_fr": "Segment antérieur/ophtalmologie de la cornée",
         "label_ar": "طب العيون (الجزء الأمامي / القرنية)",
         "value": "Anterior_segment/cornea_ophthalmology"
      },
      {
         "label_en": "Glaucoma ophthalmology",
         "label_de": "Glaukom-Augenheilkunde",
         "label_nl": "glaucoom oogheelkunde",
         "label_rs": "Офтальмология глаукомы",
         "label_sp": "oftalmología glaucoma",
         "label_pt": "glaucoma oftalmologia",
         "label_sw": "Glaucoma ophthalmolojia",
         "label_ch": "青光眼眼科",
         "label_fr": "Ophtalmologie du glaucome",
         "label_ar": "المياه الزرقاء",
         "value": "Glaucoma_ophthalmology"
      },
      {
         "label_en": "Neuro-ophthalmology",
         "label_de": "Neuro-Ophthalmologie",
         "label_nl": "Neuro-oogheelkunde",
         "label_rs": "Нейроофтальмология",
         "label_sp": "Neurooftalmología",
         "label_pt": "Neuro-oftalmologia",
         "label_sw": "Neuro-ophthalmolojia",
         "label_ch": "眼肿瘤学",
         "label_fr": "Neuro-ophtalmologie",
         "label_ar": "طب أعصاب العيون",
         "value": "Neuro-ophthalmology"
      },
      {
         "label_en": "Ocular oncology",
         "label_de": "Augenonkologie",
         "label_nl": "oculaire oncologie",
         "label_rs": "Глазная онкология",
         "label_sp": "oncología ocular",
         "label_pt": "oncologia ocular",
         "label_sw": "Oncolojia ya macho",
         "label_ch": "神经眼科",
         "label_fr": "Oncologie oculaire",
         "label_ar": "أورام العين",
         "value": "Ocular_oncology"
      },
      {
         "label_en": "Oculoplastics/orbit",
         "label_de": "Okuloplastik / Orbitaplastik",
         "label_nl": "Oculoplastics / baan",
         "label_rs": "Окулопластика/орбиты",
         "label_sp": "Oculoplástica / órbita",
         "label_pt": "Oculoplastics / órbita",
         "label_sw": "Oculoplastics / obiti",
         "label_ch": "ﾂ环板篠ｮﾂ嘉ｯﾂ偲",
         "label_fr": "Oculoplastique / orbite",
         "label_ar": "عمليات تجميل العين ومدارها",
         "value": "Oculoplastics/orbit"
      },
      {
         "label_en": "Ophthalmic Plastic & Reconstructive Surgery",
         "label_de": "Ophthalmologische plastische und rekonstruktive Chirurgie",
         "label_nl": "Oogheelkundige Plastische en Reconstructieve Chirurgie",
         "label_rs": "Офтальмологическая пластическая и реконструктивная хирургия",
         "label_sp": "Oftálmica plástica y cirugía reconstructiva",
         "label_pt": "Ophthalmic Plastic & Reconstructive Surgery",
         "label_sw": "Upasuaji wa Plastiki na Urekebishaji wa Ophthalmic",
         "label_ch": "眼科整形外科",
         "label_fr": "Chirurgie plastique et reconstructive ophtalmique",
         "label_ar": "تجميل العيون والجراحات الترميمية",
         "value": "Ophthalmic_Plastic_&_Reconstructive_Surgery"
      },
      {
         "label_en": "Retina/uveitis",
         "label_de": "Netzhaut / Uveitis",
         "label_nl": "Retina / uveitis",
         "label_rs": "Сетчатка/увеит",
         "label_sp": "Retina / uveítis",
         "label_pt": "Retina / uveíte",
         "label_sw": "Retina / uveitis",
         "label_ch": "视网膜/葡萄膜炎",
         "label_fr": "Rétine / uvéite",
         "label_ar": "الشبكية / التهاب القزحية",
         "value": "Retina/uveitis"
      },
      {
         "label_en": "Strabismus/pediatric ophthalmology",
         "label_de": "Strabismus / Kinderaugenheilkunde",
         "label_nl": "Scheelzien / pediatrische oogheelkunde",
         "label_rs": "Косоглазие/детская офтальмология",
         "label_sp": "Estrabismo / oftalmología pediátrica",
         "label_pt": "Estrabismo / oftalmologia pediátrica",
         "label_sw": "Strabismus / ophthalmolojia ya watoto",
         "label_ch": "斜视/儿科眼科",
         "label_fr": "Strabisme/ophtalmologie pédiatrique",
         "label_ar": "الحول / طب عيون الأطفال",
         "value": "Strabismus/pediatric_ophthalmology"
      },
      {
         "label_en": "Blood banking and transfusion medicine",
         "label_de": "Blutbank- und Transfusionsmedizin",
         "label_nl": "Bloedbanken en transfusie geneeskunde",
         "label_rs": "Заготовка крофи и трансфузионная медицина",
         "label_sp": "los bancos de sangre y medicina transfusional",
         "label_pt": "bancos de sangue e medicina de transfusão",
         "label_sw": "Benki ya damu na dawa ya kuongezea damu",
         "label_ch": "血库和输血医学",
         "label_fr": "Banque de sang et médecine transfusionnelle",
         "label_ar": "بنك الدم ونقل الدم",
         "value": "Blood_banking_and_transfusion_medicine"
      },
      {
         "label_en": "Chemical pathology",
         "label_de": "Anatomische Pathologie",
         "label_nl": "chemische pathologie",
         "label_rs": "Химическая патология",
         "label_sp": "patología química",
         "label_pt": "patologia química",
         "label_sw": "Ugonjwa wa kemikali",
         "label_ch": "化学病理学",
         "label_fr": "Pathologie chimique",
         "label_ar": "الباثولوجيا الكيميائية",
         "value": "Chemical_pathology"
      },
      {
         "label_en": "Cytopathology",
         "label_de": "Zytopathologie",
         "label_nl": "cytopathology",
         "label_rs": "Цитопатология",
         "label_sp": "citopatología",
         "label_pt": "citopatologia",
         "label_sw": "Cytopatholojia",
         "label_ch": "细胞病理学",
         "label_fr": "Cytopathologie",
         "label_ar": "قسم أمراض الخلية",
         "value": "Cytopathology"
      },
      {
         "label_en": "Forensic pathology",
         "label_de": "Forensische Pathologie",
         "label_nl": "forensische pathologie",
         "label_rs": "Судебная патология",
         "label_sp": "Patología Forense",
         "label_pt": "patologia forense",
         "label_sw": "Kuchunguza mauaji ya ugonjwa",
         "label_ch": "法医病理学",
         "label_fr": "Pathologie médico-légale",
         "label_ar": "أمراض الطب الشرعي",
         "value": "Forensic_pathology"
      },
      {
         "label_en": "Genetic pathology",
         "label_de": "Genetische Pathologie",
         "label_nl": "genetische pathologie",
         "label_rs": "Генетическая патология",
         "label_sp": "patología genética",
         "label_pt": "patologia genética",
         "label_sw": "Ugonjwa wa maumbile",
         "label_ch": "遗传病理学",
         "label_fr": "Pathologie génétique",
         "label_ar": "الأمراض الجينية",
         "value": "Genetic_pathology"
      },
      {
         "label_en": "Hematology",
         "label_de": "Hämatologie",
         "label_nl": "Hematologie",
         "label_rs": "Гематология",
         "label_sp": "Hematología",
         "label_pt": "Hematologia",
         "label_sw": "Hematolojia",
         "label_ch": "血液学",
         "label_fr": "Hématologie",
         "label_ar": "قسم أمراض الدم",
         "value": "Hematology"
      },
      {
         "label_en": "Immunopathology",
         "label_de": "Immunpathologie",
         "label_nl": "immunopathologie",
         "label_rs": "Иммунопатология",
         "label_sp": "inmunopatología",
         "label_pt": "Imunopatologia",
         "label_sw": "Immunopatholojia",
         "label_ch": "免疫病理学",
         "label_fr": "Immunopathologie",
         "label_ar": "أمراض المناعة",
         "value": "Immunopathology"
      },
      {
         "label_en": "Medical microbiology",
         "label_de": "Medizinische Mikrobiologie",
         "label_nl": "medische microbiologie",
         "label_rs": "Медицинская микробиология",
         "label_sp": "Microbiología médica",
         "label_pt": "microbiologia médica",
         "label_sw": "Matibabu ya microbiolojia",
         "label_ch": "医学微生物学",
         "label_fr": "Microbiologie médicale",
         "label_ar": "الميكروبيولوجي",
         "value": "Medical_microbiology"
      },
      {
         "label_en": "Molecular pathology",
         "label_de": "Molekulare Pathologie",
         "label_nl": "moleculaire pathologie",
         "label_rs": "Молекулярная патология",
         "label_sp": "patología molecular",
         "label_pt": "patologia molecular",
         "label_sw": "ugonjwa wa Masi",
         "label_ch": "分子病理学",
         "label_fr": "Pathologie moléculaire",
         "label_ar": "الأمراض الجزيئية",
         "value": "Molecular_pathology"
      },
      {
         "label_en": "Neuropathology",
         "label_de": "Neuropathologie",
         "label_nl": "neuropathologie",
         "label_rs": "Невропатология",
         "label_sp": "neuropatología",
         "label_pt": "neuropatologia",
         "label_sw": "Neuropatholojia",
         "label_ch": "神经病理学",
         "label_fr": "Neuropathologie",
         "label_ar": "أمراض الأعصاب",
         "value": "Neuropathology"
      },
      {
         "label_en": "Pediatric pathology",
         "label_de": "Pädiatrische Pathologie",
         "label_nl": "pediatrische pathologie",
         "label_rs": "Детская патология",
         "label_sp": "la patología pediátrica",
         "label_pt": "patologia pediátrica",
         "label_sw": "ugonjwa wa watoto",
         "label_ch": "儿科病理",
         "label_fr": "Pathologie pédiatrique",
         "label_ar": "باثولوجيا الأطفال",
         "value": "Pediatric_pathology"
      },
      {
         "label_en": "Adolescent medicine",
         "label_de": "Jugendmedizin",
         "label_nl": "adolescent Medicine",
         "label_rs": "Подростковая медицина",
         "label_sp": "medicina adolescente",
         "label_pt": "medicina adolescente",
         "label_sw": "Dawa ya vijana",
         "label_ch": "青少年医学",
         "label_fr": "Médecine des adolescents",
         "label_ar": "طب المراهقين",
         "value": "Adolescent_medicine"
      },
      {
         "label_en": "Child abuse pediatrics",
         "label_de": "Kindesmissbrauchsprävention",
         "label_nl": "Kindermishandeling kindergeneeskunde",
         "label_rs": "Педиатрия жестокого обращения с детьми",
         "label_sp": "pediatría de abuso infantil",
         "label_pt": "pediatria abuso infantil",
         "label_sw": "Matibabu ya watoto  wanaodhulumia",
         "label_ch": "儿童虐待儿科",
         "label_fr": "Pédiatrie des enfants maltraités",
         "label_ar": "الاعتداء على الأطفال",
         "value": "Child_abuse_pediatrics"
      },
      {
         "label_en": "Developmental-behavioral pediatrics",
         "label_de": "Entwicklungsverhaltenspädiatrie",
         "label_nl": "Developmental-behavioral kindergeneeskunde",
         "label_rs": "Развивающая и поведенческая педиатрия",
         "label_sp": "pediatría del desarrollo y de comportamiento",
         "label_pt": "pediatria Developmental-comportamentais",
         "label_sw": "Watoto wa maendeleo kitabia",
         "label_ch": "发育行为儿科",
         "label_fr": "Pédiatrie développementale et comportementale",
         "label_ar": "طب الأطفال السلوكي ",
         "value": "Developmental-behavioral_pediatrics"
      },
      {
         "label_en": "Neonatal-perinatal medicine",
         "label_de": "Medizin für Neugeborene und Perinatale",
         "label_nl": "Neonatale-perinatale geneeskunde",
         "label_rs": "Неонатально-перинатальная медицина",
         "label_sp": "medicina neonatal-perinatal",
         "label_pt": "medicina neonatal-perinatal",
         "label_sw": "Dawa ya kuzaliwa kwa mtoto mchanga",
         "label_ch": "新生儿-围产期医学",
         "label_fr": "Médecine néonatale et périnatale",
         "label_ar": "طب الأطفال حديثي الولادة",
         "value": "Neonatal-perinatal_medicine"
      },
      {
         "label_en": "Pediatric cardiology",
         "label_de": "Pädiatrische Kardiologie",
         "label_nl": "pediatrische cardiologie",
         "label_rs": "Детская кардиология",
         "label_sp": "cardiología pediátrica",
         "label_pt": "cardiologia pediátrica",
         "label_sw": "Ugonjwa wa moyo ya watoto",
         "label_ch": "儿科心脏病",
         "label_fr": "Cardiologie pédiatrique",
         "label_ar": "أمراض قلب الأطفال",
         "value": "Pediatric_cardiology"
      },
      {
         "label_en": "Pediatric critical care medicine",
         "label_de": "Pädiatrische Intensivmedizin",
         "label_nl": "Pediatrische intensive care geneeskunde",
         "label_rs": "Медицина критических состояний в педиатрии",
         "label_sp": "Pediátrica medicina de cuidados críticos",
         "label_pt": "medicina intensiva pediátrica",
         "label_sw": "Dawa ya utunzaji wa watoto muhimu",
         "label_ch": "儿科重症监护医学",
         "label_fr": "Médecine pédiatrique de soins intensifs",
         "label_ar": "العناية المركزة للأطفال",
         "value": "Pediatric_critical_care_medicine"
      },
      {
         "label_en": "Pediatric endocrinology",
         "label_de": "Pädiatrische Endokrinologie",
         "label_nl": "pediatrische endocrinologie",
         "label_rs": "Детская эндокринология",
         "label_sp": "endocrinología pediátrica",
         "label_pt": "endocrinologia pediátrica",
         "label_sw": "Endocrinolojia ya watoto",
         "label_ch": "儿科内分泌学",
         "label_fr": "Endocrinologie pédiatrique",
         "label_ar": "طب الغدد الصماء للأطفال",
         "value": "Pediatric_endocrinology"
      },
      {
         "label_en": "Pediatric gastroenterology",
         "label_de": "Pädiatrische Gastroenterologie",
         "label_nl": "pediatrische gastro-enterologie",
         "label_rs": "Детская гастроэнтерология",
         "label_sp": "gastroenterología pediátrica",
         "label_pt": "gastroenterologia pediátrica",
         "label_sw": "Gastroenterolojia ya watoto",
         "label_ch": "小儿胃肠病学",
         "label_fr": "Gastroentérologie pédiatrique",
         "label_ar": "أمراض الجهاز الهضمي للأطفال",
         "value": "Pediatric_gastroenterology"
      },
      {
         "label_en": "Pediatric hematology-oncology",
         "label_de": "Pädiatrische Hämatologie-Onkologie",
         "label_nl": "Pediatrische hematologie-oncologie",
         "label_rs": "Детская гематология-онкология",
         "label_sp": "Hematología Pediátrica-oncología",
         "label_pt": "Pediatric Hematology-Oncology",
         "label_sw": "hematology-oncolojia ya watoto",
         "label_ch": "小儿血液学-肿瘤学",
         "label_fr": "Hématologie-oncologie pédiatrique",
         "label_ar": "أمراض الدم والأورام للأطفال",
         "value": "Pediatric_hematology-oncology"
      },
      {
         "label_en": "Pediatric infectious diseases",
         "label_de": "Pädiatrische Infektionskrankheiten",
         "label_nl": "Pediatrische infectieziekten",
         "label_rs": "Детские инфекционные заболевания",
         "label_sp": "enfermedades infecciosas pediátricas",
         "label_pt": "doenças infecciosas pediátricos",
         "label_sw": "Pediatric infectious diseases",
         "label_ch": "小儿传染病",
         "label_fr": "Maladies infectieuses pédiatriques",
         "label_ar": "أمراض الأطفال المعدية",
         "value": "Pediatric_infectious_diseases"
      },
      {
         "label_en": "Pediatric nephrology",
         "label_de": "Pädiatrische Nephrologie",
         "label_nl": "Pediatric Nephrology",
         "label_rs": "Детская нефрология",
         "label_sp": "nefrología pediátrica",
         "label_pt": "nefrologia pediátrica",
         "label_sw": "Pediatric nephrology",
         "label_ch": "小儿肾病",
         "label_fr": "Néphrologie pédiatrique",
         "label_ar": "طب كلى الأطفال",
         "value": "Pediatric_nephrology"
      },
      {
         "label_en": "Pediatric pulmonology",
         "label_de": "Pädiatrische Pulmonologie",
         "label_nl": "Kinderpneumologie",
         "label_rs": "Детская пульмонология",
         "label_sp": "neumología pediátrica",
         "label_pt": "pneumologia pediátrica",
         "label_sw": "Ugonjwa wa mapafu ya watoto",
         "label_ch": "小儿肺病",
         "label_fr": "Pneumologie pédiatrique",
         "label_ar": "أمراض الصدر للأطفال",
         "value": "Pediatric_pulmonology"
      },
      {
         "label_en": "Pediatric rheumatology",
         "label_de": "Pädiatrische Rheumatologie",
         "label_nl": "pediatrische reumatologie",
         "label_rs": "Детская ревматология",
         "label_sp": "reumatología pediátrica",
         "label_pt": "reumatologia pediátrica",
         "label_sw": "rheumatolojia ya watoto",
         "label_ch": "小儿风湿病",
         "label_fr": "Rhumatologie pédiatrique",
         "label_ar": "روماتيزم الأطفال",
         "value": "Pediatric_rheumatology"
      },
      {
         "label_en": "Pediatric sports medicine",
         "label_de": "Pädiatrische Sportmedizin",
         "label_nl": "Pediatric sportgeneeskunde",
         "label_rs": "Детская спортивная медицина",
         "label_sp": "medicina deportiva pediátrica",
         "label_pt": "medicina esportiva pediátricos",
         "label_sw": "Dawa ya michezo ya watoto",
         "label_ch": "小儿运动医学",
         "label_fr": "Médecine sportive pédiatrique",
         "label_ar": "طب الأطفال الرياضي",
         "value": "Pediatric_sports_medicine"
      },
      {
         "label_en": "Pediatric transplant hepatology",
         "label_de": "Pädiatrische Transplantationshepatologie",
         "label_nl": "Pediatric transplantatie hepatologie",
         "label_rs": "Детская трансплантационная гепатология",
         "label_sp": "hepatología trasplantes pediátricos",
         "label_pt": "hepatologia pediátrica transplante",
         "label_sw": "hepatolojia ya kupandikiza watoto",
         "label_ch": "小儿移植肝病",
         "label_fr": "Hépatologie de la transplantation pédiatrique",
         "label_ar": "زراعة الكبد للأطفال",
         "value": "Pediatric_transplant_hepatology"
      },
      {
         "label_en": "Brain injury medicine",
         "label_de": "Arzneimittel gegen Hirnverletzungen",
         "label_nl": "Hersenletsel geneeskunde",
         "label_rs": "Медицина ЧМТ",
         "label_sp": "medicina lesión cerebral",
         "label_pt": "Cérebro medicina lesão",
         "label_sw": "Dawa ya kuumia ubongo",
         "label_ch": "脑损伤医学",
         "label_fr": "Médecine des lésions cérébrales",
         "label_ar": "طب إصابات الدماغ",
         "value": "Brain_injury_medicine"
      },
      {
         "label_en": "Hospice and palliative medicine",
         "label_de": "Hospiz und Palliativmedizin",
         "label_nl": "Hospice en palliatieve geneeskunde",
         "label_rs": "Хоспис и паллиативная медицина",
         "label_sp": "Hospicio y medicina paliativa",
         "label_pt": "Hospício e medicina paliativa",
         "label_sw": "Hospitali na dawa ya kutuliza",
         "label_ch": "临终关怀和姑息药",
         "label_fr": "Hospice et médecine palliative",
         "label_ar": "رعاية المحتضرين والرعاية المخففة للآلام",
         "value": "Hospice_and_palliative_medicine"
      },
      {
         "label_en": "Neuromuscular medicine",
         "label_de": "Neuromuskuläre Medizin",
         "label_nl": "neuromusculaire geneeskunde",
         "label_rs": "Нервно-мышечная медицина",
         "label_sp": "medicina neuromuscular",
         "label_pt": "medicina neuromuscular",
         "label_sw": "Dawa ya mshipa wa neva",
         "label_ch": "神经肌肉医学",
         "label_fr": "Médecine neuromusculaire",
         "label_ar": "طب الأعصاب والعضلات",
         "value": "Neuromuscular_medicine"
      },
      {
         "label_en": "Pain medicine",
         "label_de": "Schmerzmittel",
         "label_nl": "Pijnmedicatie",
         "label_rs": "Медицина болевых синдромов",
         "label_sp": "Medicina para el dolor",
         "label_pt": "Analgésico",
         "label_sw": "Dawa ya maumivu",
         "label_ch": "止痛药",
         "label_fr": "Médicaments antidouleur",
         "label_ar": "طب الألم",
         "value": "Pain_medicine"
      },
      {
         "label_en": "Pediatric rehabilitation medicine",
         "label_de": "Pädiatrische Rehabilitationsmedizin",
         "label_nl": "Pediatric revalidatiegeneeskunde",
         "label_rs": "Детская реабилитационная медицина",
         "label_sp": "medicina de rehabilitación pediátrica",
         "label_pt": "medicina de reabilitação pediátrica",
         "label_sw": "Dawa ya ukarabati wa watoto",
         "label_ch": "小儿康复医学",
         "label_fr": "Médecine de réadaptation pédiatrique",
         "label_ar": "طب إعادة تأهيل الأطفال",
         "value": "Pediatric_rehabilitation_medicine"
      },
      {
         "label_en": "Spinal cord injury medicine",
         "label_de": "Arzneimittel gegen Rückenmarksverletzungen",
         "label_nl": "Ruggenmergletsel geneeskunde",
         "label_rs": "Медицина травм спинного мозга",
         "label_sp": "medicina lesión medular",
         "label_pt": "medula espinhal medicina lesão",
         "label_sw": "Dawa ya kuumia ya uti wa mgongo",
         "label_ch": "脊髓损伤医学",
         "label_fr": "Médecine des lésions de la moelle épinière",
         "label_ar": "إصابات العمود الفقري",
         "value": "Spinal_cord_injury_medicine"
      },
      {
         "label_en": "Sports medicine",
         "label_de": "Sportmedizin",
         "label_nl": "sportgeneeskunde",
         "label_rs": "Спортивная медицина",
         "label_sp": "Medicina deportiva",
         "label_pt": "medicina desportiva",
         "label_sw": "Dawa ya michezo",
         "label_ch": "运动医学",
         "label_fr": "Médecine du sport",
         "label_ar": "الطب الرياضي",
         "value": "Sports_medicine"
      },
      {
         "label_en": "Aerospace medicine",
         "label_de": "Luft-und_Raumfahrtmedizin",
         "label_nl": "Aerospace geneeskunde",
         "label_rs": "Авиационно-космическая медицина",
         "label_sp": "medicina aeroespacial",
         "label_pt": "medicina aeroespacial",
         "label_sw": "Dawa ya kutumia angani",
         "label_ch": "航空医学",
         "label_fr": "Médecine aérospatiale",
         "label_ar": "طب الطيران",
         "value": "Aerospace_medicine"
      },
      {
         "label_en": "Medical toxicology",
         "label_de": "Medizinische Toxikologie",
         "label_nl": "medische toxicologie",
         "label_rs": "Медицинская токсикология",
         "label_sp": "toxicología médica",
         "label_pt": "toxicologia médica",
         "label_sw": "Matibabu ya sumu",
         "label_ch": "医学毒理学",
         "label_fr": "Toxicologie médicale",
         "label_ar": "علم السموم الطبية",
         "value": "Medical_toxicology"
      },
      {
         "label_en": "Occupational medicine",
         "label_de": "Arbeitsmedizin",
         "label_nl": "arbeidsgeneeskunde",
         "label_rs": "Медицина труда",
         "label_sp": "Medicina Ocupacional",
         "label_pt": "Medicina Ocupacional",
         "label_sw": "Dawa ya kazini",
         "label_ch": "职业医学",
         "label_fr": "Médecine du travail",
         "label_ar": "الطب المهني",
         "value": "Occupational_medicine"
      },
      {
         "label_en": "Public health medicine",
         "label_de": "Public health medicine",
         "label_nl": "Volksgezondheid geneeskunde",
         "label_rs": "Медицина общественного здравоохранения",
         "label_sp": "medicina de salud pública",
         "label_pt": "medicina saúde pública",
         "label_sw": "Dawa ya afya ya umma",
         "label_ch": "公共卫生医学",
         "label_fr": "Médecine de santé publique",
         "label_ar": "طب الصحة العامة",
         "value": "Public_health_medicine"
      },
      {
         "label_en": "Administrative psychiatry",
         "label_de": "Verwaltungspsychiatrie",
         "label_nl": "administratieve psychiatrie",
         "label_rs": "Административная психиатрия",
         "label_sp": "psiquiatría administrativa",
         "label_pt": "psiquiatria administrativa",
         "label_sw": "Saikolojia ya kiutawala",
         "label_ch": "行政精神病学",
         "label_fr": "Psychiatrie administrative",
         "label_ar": "الطب النفسي الإداري",
         "value": "Administrative_psychiatry"
      },
      {
         "label_en": "Child and adolescent psychiatry",
         "label_de": "Kinder-und Jugendpsychiatrie",
         "label_nl": "Kinder- en jeugdpsychiatrie",
         "label_rs": "Детская и подростковая психиатрия",
         "label_sp": "la psiquiatría infantil y adolescente",
         "label_pt": "Psiquiatria infantil e adolescente",
         "label_sw": "Saikolojia ya watoto na vijana",
         "label_ch": "儿童和青少年精神病学",
         "label_fr": "Psychiatrie de l'enfant et de l'adolescent",
         "label_ar": "الطب النفسي للأطفال والمراهقين",
         "value": "Child_and_adolescent_psychiatry"
      },
      {
         "label_en": "Community psychiatry",
         "label_de": "Gemeinschaftspsychiatrie",
         "label_nl": "Community psychiatrie",
         "label_rs": "Общественная психиатрия",
         "label_sp": "psiquiatría comunitaria",
         "label_pt": "psiquiatria comunitária",
         "label_sw": "Saikolojia ya jamii",
         "label_ch": "社区精神病学",
         "label_fr": "Psychiatrie communautaire",
         "label_ar": "الطب النفسي المجتمعي",
         "value": "Community_psychiatry"
      },
      {
         "label_en": "Consultation/liaison psychiatry",
         "label_de": "Konsultation / Verbindungspsychiatrie",
         "label_nl": "Overleg / liaisonpsychiatrie",
         "label_rs": "Консультативная психиатрия",
         "label_sp": "Consulta / psiquiatría de enlace",
         "label_pt": "Consulta / psiquiatria de ligação",
         "label_sw": "Mashauriano / uhusiano wa kisiakolojia",
         "label_ch": "咨询/联络精神病学",
         "label_fr": "Psychiatrie de consultation/liaison",
         "label_ar": "الطب النفسي الاستشاري للعلاقات",
         "value": "Consultation/liaison_psychiatry"
      },
      {
         "label_en": "Emergency psychiatry",
         "label_de": "Notfallpsychiatrie",
         "label_nl": "Emergency psychiatrie",
         "label_rs": "Неотложная психиатрия",
         "label_sp": "psiquiatría de emergencia",
         "label_pt": "psiquiatria de emergência",
         "label_sw": "Saikolojia ya dharura",
         "label_ch": "紧急精神病学",
         "label_fr": "Psychiatrie d'urgence",
         "label_ar": "الطب النفسي للطوارئ",
         "value": "Emergency_psychiatry"
      },
      {
         "label_en": "Forensic psychiatry",
         "label_de": "Forensische Psychiatrie",
         "label_nl": "forensische psychiatrie",
         "label_rs": "Судебная психиатрия",
         "label_sp": "psiquiatría forense",
         "label_pt": "psiquiatria forense",
         "label_sw": "Saikolojia ya kiuchunguzi",
         "label_ch": "法医精神病学",
         "label_fr": "Psychiatrie légale",
         "label_ar": "الطب النفسي الشرعي",
         "value": "Forensic_psychiatry"
      },
      {
         "label_en": "Geriatric psychiatry",
         "label_de": "Geriatrische Psychiatrie",
         "label_nl": "ouderenpsychiatrie",
         "label_rs": "Гериатрическая психиатрия",
         "label_sp": "psiquiatría geriátrica",
         "label_pt": "psiquiatria geriátrica",
         "label_sw": "Saikolojia ya kizazi",
         "label_ch": "老年精神病学",
         "label_fr": "Geriatric psychiatry",
         "label_ar": "الطب النفسي لكبار السن",
         "value": "Geriatric_psychiatry"
      },
      {
         "label_en": "Mental retardation psychiatry",
         "label_de": "Psychiatrie für geistige Behinderung",
         "label_nl": "Mentale retardatie psychiatrie",
         "label_rs": "Психиатрия умственной отсталости",
         "label_sp": "psiquiatría retraso mental",
         "label_pt": "psiquiatria retardo mental",
         "label_sw": "Saikolojia ya kudhoofika kwa akili",
         "label_ch": "精神发育迟缓精神病学",
         "label_fr": "Psychiatrie du retard mental",
         "label_ar": "الطب النفسي للتأخر العقلي",
         "value": "Mental_retardation_psychiatry"
      },
      {
         "label_en": "Military psychiatry",
         "label_de": "Militärpsychiatrie",
         "label_nl": "militaire psychiatrie",
         "label_rs": "Военная психиатрия",
         "label_sp": "psiquiatría militar",
         "label_pt": "psiquiatria militar",
         "label_sw": "Saikolojia ya jeshi",
         "label_ch": "军事精神病学",
         "label_fr": "Psychiatrie militaire",
         "label_ar": "الطب النفسي العسكري",
         "value": "Military_psychiatry"
      },
      {
         "label_en": "Pain medicine",
         "label_de": "Schmerzmittel",
         "label_nl": "Pijnmedicatie",
         "label_rs": "Медицина болевых синдромов",
         "label_sp": "Medicina para el dolor",
         "label_pt": "Analgésico",
         "label_sw": "Dawa ya maumivu",
         "label_ch": "止痛药",
         "label_fr": "Médicaments antidouleur",
         "label_ar": "طب الألم",
         "value": "Pain_medicine"
      },
      {
         "label_en": "Psychiatric research",
         "label_de": "Psychiatrische_Forschung",
         "label_nl": "psychiatrisch onderzoek",
         "label_rs": "Психиатрические исследования",
         "label_sp": "La investigación psiquiátrica",
         "label_pt": "pesquisa psiquiátrica",
         "label_sw": "Utafiti wa kisiakolojia",
         "label_ch": "精神病学研究",
         "label_fr": "Recherche en psychiatrie",
         "label_ar": "البحوث النفسية",
         "value": "Psychiatric_research"
      },
      {
         "label_en": "Psychosomatic medicine",
         "label_de": "Psychosomatische Medizin",
         "label_nl": "psychosomatische geneeskunde",
         "label_rs": "Психосоматическая медицина",
         "label_sp": "La medicina psicosomática",
         "label_pt": "medicina psicossomática",
         "label_sw": "Dawa ya kisaikolojia",
         "label_ch": "心身医学",
         "label_fr": "Médecine psychosomatique",
         "label_ar": "الطب النفسي الجسدي",
         "value": "Psychosomatic_medicine"
      },
      {
         "label_en": "Hospice and palliative medicine",
         "label_de": "Hospiz und Palliativmedizin",
         "label_nl": "Hospice en palliatieve geneeskunde",
         "label_rs": "Хоспис и паллиативная медицина",
         "label_sp": "Hospicio y medicina paliativa",
         "label_pt": "Hospício e medicina paliativa",
         "label_sw": "Hospitali na dawa ya kutuliza",
         "label_ch": "临终关怀和姑息药",
         "label_fr": "Hospice et médecine palliative",
         "label_ar": "رعاية المحتضرين والرعاية المخففة للآلام",
         "value": "Hospice_and_palliative_medicine"
      },
      {
         "label_en": "Pain medicine",
         "label_de": "Schmerzmittel",
         "label_nl": "Pijnmedicatie",
         "label_rs": "Медицина болевых синдромов",
         "label_sp": "Medicina para el dolor",
         "label_pt": "Analgésico",
         "label_sw": "Dawa ya maumivu",
         "label_ch": "止痛药",
         "label_fr": "Médicaments antidouleur",
         "label_ar": "طب الألم",
         "value": "Pain_medicine"
      },
      {
         "label_en": "Colon and rectal surgery",
         "label_de": "Colon and rectal surgery",
         "label_nl": "Colon en rectale chirurgie",
         "label_rs": "Хирургия толстой и прямой кишки",
         "label_sp": "Colon y cirugía rectal",
         "label_pt": "Cólon e cirurgia rectal",
         "label_sw": "Oparesheni wa Colon na rectal",
         "label_ch": "结肠和直肠手术",
         "label_fr": "Chirurgie du côlon et du rectum",
         "label_ar": "جراحة القولون والمستقيم",
         "value": "Colon_and_rectal_surgery"
      },
      {
         "label_en": "Surgical critical care",
         "label_de": "Chirurgische Intensivpflege",
         "label_nl": "Chirurgische intensive care",
         "label_rs": "Хирургическая реанимация",
         "label_sp": "cuidados intensivos quirúrgicos",
         "label_pt": "cuidados intensivos cirúrgica",
         "label_sw": "Huduma muhimu ya Oparesheni",
         "label_ch": "外科重症监护",
         "label_fr": "Soins intensifs chirurgicaux",
         "label_ar": "جراحات العناية المركزة",
         "value": "Surgical_critical_care"
      },
      {
         "label_en": "Gynecologic oncology",
         "label_de": "Gynäkologische Onkologie",
         "label_nl": "gynaecologische oncologie",
         "label_rs": "Гинекологическая онкология",
         "label_sp": "Oncología Ginecológica",
         "label_pt": "oncologia ginecológica",
         "label_sw": "Oncolojia ya Gynecologic",
         "label_ch": "妇科肿瘤",
         "label_fr": "Oncologie gynécologique",
         "label_ar": "طب الأورام النسائية",
         "value": "Gynecologic_oncology"
      },
      {
         "label_en": "Plastic surgery",
         "label_de": "Plastische Chirurgie",
         "label_nl": "Plastische chirurgie",
         "label_rs": "Пластическая хирургия",
         "label_sp": "Cirugía plástica",
         "label_pt": "Cirurgia plástica",
         "label_sw": "Oparesheni wa kiplastiki",
         "label_ch": "整形外科",
         "label_fr": "Chirurgie plastique",
         "label_ar": "جراحات التجميل",
         "value": "Plastic_surgery"
      },
      {
         "label_en": "Craniofacial surgery",
         "label_de": "Kraniofaziale Chirurgie",
         "label_nl": "craniofaciale chirurgie",
         "label_rs": "Черепно-лицевая хирургия",
         "label_sp": "cirugía craneofacial",
         "label_pt": "cirurgia craniofacial",
         "label_sw": "Craniofacial surgery",
         "label_ch": "颅面外科",
         "label_fr": "Chirurgie cranio-faciale",
         "label_ar": "جراحات العصب السابع",
         "value": "Craniofacial_surgery"
      },
      {
         "label_en": "Hand surgery",
         "label_de": "Handchirurgie",
         "label_nl": "handchirurgie",
         "label_rs": "Хирургия кисти",
         "label_sp": "cirugía de la mano",
         "label_pt": "cirurgia da mão",
         "label_sw": "Oparesheni wa mkono",
         "label_ch": "手部手术",
         "label_fr": "Chirurgie de la main",
         "label_ar": "جراحات اليد",
         "value": "Hand_surgery"
      },
      {
         "label_en": "Neurological surgery",
         "label_de": "Neurologische Chirurgie",
         "label_nl": "neurologische chirurgie",
         "label_rs": "Неврологическая хирургия",
         "label_sp": "cirugía neurológica",
         "label_pt": "cirurgia neurológica",
         "label_sw": "Oparesheni wa neva",
         "label_ch": "神经外科手术",
         "label_fr": "Chirurgie neurologique",
         "label_ar": "جراحات الأعصاب",
         "value": "Neurological_surgery"
      },
      {
         "label_en": "Endovascular surgical neuroradiology",
         "label_de": "Endovaskuläre chirurgische Neuroradiologie",
         "label_nl": "Endovasculaire chirurgische neuroradiologie",
         "label_rs": "Эндоваскулярная хирургическая нейрорадиология",
         "label_sp": "neurorradiología quirúrgica endovascular",
         "label_pt": "neurorradiologia cirúrgica endovascular",
         "label_sw": "Neuroradiolojia ya upasuaji wa mishipa",
         "label_ch": "血管内手术神经放射学",
         "label_fr": "Neuroradiologie chirurgicale endovasculaire",
         "label_ar": "جراحة الأوعية الدموية العصبية",
         "value": "Endovascular_surgical_neuroradiology"
      },
      {
         "label_en": "Ophthalmic surgery",
         "label_de": "Augenchirurgie",
         "label_nl": "oogchirurgie",
         "label_rs": "Офтальмологическая хирургия",
         "label_sp": "cirugía oftálmica",
         "label_pt": "cirurgia oftálmica",
         "label_sw": "Oparesheni ya macho",
         "label_ch": "眼科手术",
         "label_fr": "Chirurgie ophtalmique",
         "label_ar": "جراحات العيون",
         "value": "Ophthalmic_surgery"
      },
      {
         "label_en": "Oral and maxillofacial surgery",
         "label_de": "Mund-und_Kieferchirurgie",
         "label_nl": "Mondziekten en Kaakchirurgie",
         "label_rs": "Челюстно-лицевая хирургия",
         "label_sp": "Cirugía Oral y Maxilofacial",
         "label_pt": "Cirurgia Oral e Maxilofacial",
         "label_sw": "Oral and maxillofacial surgery",
         "label_ch": "Oral and maxillofacial surgery",
         "label_fr": "Chirurgie buccale et maxillo-faciale",
         "label_ar": "جراحات الوجه والفكين",
         "value": "Oral_and_maxillofacial_surgery"
      },
      {
         "label_en": "HEAD AND NECK SURGERY",
         "label_de": "KOPF-UND HALSCHIRURGIE",
         "label_nl": "HEAD and Neck Surgery",
         "label_rs": "Хирургия головы и шеи",
         "label_sp": "CIRUGÍA DE CABEZA Y CUELLO",
         "label_pt": "CABEÇA E PESCOÇO CIRURGIA",
         "label_sw": "OPARESHENI WA KICHWA NA SHINGO",
         "label_ch": "头颈部手术",
         "label_fr": "CHIRURGIE DE LA TÊTE ET DU COU",
         "label_ar": "جراحات الرأس والرقبة",
         "value": "HEAD_AND_NECK_SURGERY"
      },
      {
         "label_en": "Orthopaedic surgery",
         "label_de": "Orthopädische Chirurgie",
         "label_nl": "Orthopedische chirurgie",
         "label_rs": "Ортопедическая хирургия",
         "label_sp": "Cirugía ortopedica",
         "label_pt": "Cirurgia Ortopédica",
         "label_sw": "Oparesheni wa mifupa",
         "label_ch": "骨科手术",
         "label_fr": "Chirurgie orthopédique",
         "label_ar": "جراحة العظام",
         "value": "Orthopaedic_surgery"
      },
      {
         "label_en": "Adult reconstructive orthopaedics",
         "label_de": "Rekonstruktive Orthopädie für Erwachsene",
         "label_nl": "Adult reconstructieve orthopedie",
         "label_rs": "Реконструктивная ортопедия",
         "label_sp": "ortopedia reconstructiva para adultos",
         "label_pt": "ortopedia reconstrutivas adultos",
         "label_sw": "Ujenzi ya mifupa ya watu wazima",
         "label_ch": "成人重建骨科",
         "label_fr": "Orthopédie reconstructive pour adultes",
         "label_ar": "جراحة العظام الترميمية للبالغين",
         "value": "Adult_reconstructive_orthopaedics"
      },
      {
         "label_en": "Foot and ankle orthopaedics",
         "label_de": "Foot and ankle orthopaedics",
         "label_nl": "Voet en enkel orthopedie",
         "label_rs": "Ортопедия стопы и голеностопного сустава",
         "label_sp": "De pie y tobillo ortopedia",
         "label_pt": "Pé e tornozelo ortopedia",
         "label_sw": "Hali ya mfupa wa mguu na kifundo",
         "label_ch": "足踝骨科",
         "label_fr": "Orthopédie du pied et de la cheville",
         "label_ar": "جراحة عظام القدم والكاحل",
         "value": "Foot_and_ankle_orthopaedics"
      },
      {
         "label_en": "Musculoskeletal oncology",
         "label_de": "Musculoskeletal oncology",
         "label_nl": "bewegingsapparaat oncologie",
         "label_rs": "Онкология опорно-двигательного аппарата",
         "label_sp": "oncología musculoesquelético",
         "label_pt": "oncologia músculo-esquelético",
         "label_sw": "Onkolojia ya mifupa",
         "label_ch": "肌肉骨骼肿瘤学",
         "label_fr": "Oncologie musculo-squelettique",
         "label_ar": "أورام الجهاز العضلي الهيكلي",
         "value": "Musculoskeletal_oncology"
      },
      {
         "label_en": "Orthopaedic sports medicine",
         "label_de": "Orthopaedic sports medicine",
         "label_nl": "Orthopedische sportgeneeskunde",
         "label_rs": "Ортопедическая спортивная медицина",
         "label_sp": "medicina deportiva ortopédica",
         "label_pt": "medicina esportiva ortopédica",
         "label_sw": "Dawa ya michezo ya mifupa",
         "label_ch": "骨科运动医学",
         "label_fr": "Médecine sportive orthopédique",
         "label_ar": "الطب الرياضي لتقويم العظام",
         "value": "Orthopaedic_sports_medicine"
      },
      {
         "label_en": "Orthopaedic surgery of the spine",
         "label_de": "Onkologie des Bewegungsapparates",
         "label_nl": "Orthopedische chirurgie van de wervelkolom",
         "label_rs": "Ортопедическая хирургия позвоночника",
         "label_sp": "La cirugía ortopédica de la columna vertebral",
         "label_pt": "cirurgia ortopédica da coluna vertebral",
         "label_sw": "Upasuaji wa mifupa ya mgongo",
         "label_ch": "脊柱骨科手术",
         "label_fr": "Chirurgie orthopédique de la colonne vertébrale",
         "label_ar": "جراحة عظام العمود الفقري",
         "value": "Orthopaedic_surgery_of_the_spine"
      },
      {
         "label_en": "Orthopaedic trauma",
         "label_de": "Orthopädische Sportmedizin",
         "label_nl": "orthopedisch trauma",
         "label_rs": "Ортопедическая травма",
         "label_sp": "trauma ortopédico",
         "label_pt": "trauma ortopédico",
         "label_sw": "kiwewe cha mifupa",
         "label_ch": "骨科创伤",
         "label_fr": "Traumatisme orthopédique",
         "label_ar": "إصابات العظام",
         "value": "Orthopaedic_trauma"
      },
      {
         "label_en": "Pediatric orthopaedics",
         "label_de": "Orthopädische Chirurgie der Wirbelsäule",
         "label_nl": "pediatrische orthopedie",
         "label_rs": "Детская ортопедия",
         "label_sp": "ortopedia pediátrica",
         "label_pt": "ortopedia pediátrica",
         "label_sw": "Mifupa ya watoto",
         "label_ch": "小儿骨科",
         "label_fr": "Orthopédie pédiatrique",
         "label_ar": "جراحة عظام الأطفال",
         "value": "Pediatric_orthopaedics"
      },
      {
         "label_en": "Otolaryngology",
         "label_de": "Orthopädisches Trauma",
         "label_nl": "Otolaryngology",
         "label_rs": "Отоларингология",
         "label_sp": "otorrinolaringología",
         "label_pt": "Otolaryngology",
         "label_sw": "Otolaryngolojia",
         "label_ch": "耳鼻喉科",
         "label_fr": "Otolaryngologie",
         "label_ar": "الأنف والأذن والحنجرة",
         "value": "Otolaryngology"
      },
      {
         "label_en": "Pediatric otolaryngology",
         "label_de": "Kinderorthopädie",
         "label_nl": "Pediatric Otolaryngology",
         "label_rs": "Детская отоларингология",
         "label_sp": "otorrinolaringología pediátrica",
         "label_pt": "otorrinolaringologia pediátrica",
         "label_sw": "Otolaryngolojia ya watoto",
         "label_ch": "小儿耳鼻喉科",
         "label_fr": "Otolaryngologie pédiatrique",
         "label_ar": "طب الأذن والأنف والحنجرة للأطفال",
         "value": "Pediatric_otolaryngology"
      },
      {
         "label_en": "Otology neurotology",
         "label_de": "Otologie Neurotologie",
         "label_nl": "otologie Neurotology",
         "label_rs": "Отология и нейротология",
         "label_sp": "neurotología otología",
         "label_pt": "otologia neurotology",
         "label_sw": "Neurolojia ya Otolojia",
         "label_ch": "耳科神经学",
         "label_fr": "Otologie neurotologie",
         "label_ar": "طب أعصاب الأذن",
         "value": "Otology_neurotology"
      },
      {
         "label_en": "Pediatric surgery",
         "label_de": "Kinderchirurgie",
         "label_nl": "Pediatrische chirurgie",
         "label_rs": "Детская хирургия",
         "label_sp": "Cirugía Pediátrica",
         "label_pt": "Cirurgia pediátrica",
         "label_sw": "Oparesheni ya wa watoto",
         "label_ch": "小儿外科",
         "label_fr": "Chirurgie pédiatrique",
         "label_ar": "جراحة الأطفال",
         "value": "Pediatric_surgery"
      },
      {
         "label_en": "Neonatal",
         "label_de": "Neugeborene",
         "label_nl": "neonatale",
         "label_rs": "Неонатальный",
         "label_sp": "neonatal",
         "label_pt": "neonatal",
         "label_sw": "Mtoto mchanga",
         "label_ch": "新生儿",
         "label_fr": "Néonatal",
         "label_ar": "حديثي الولادة",
         "value": "Neonatal"
      },
      {
         "label_en": "Prenatal",
         "label_de": "Vorgeburtlich",
         "label_nl": "prenataal",
         "label_rs": "Пренатальный",
         "label_sp": "Prenatal",
         "label_pt": "Pré-natal",
         "label_sw": "kabla ya kuzaa",
         "label_ch": "产前",
         "label_fr": "Prénatal",
         "label_ar": "ما قبل الولادة",
         "value": "Prenatal"
      },
      {
         "label_en": "Trauma",
         "label_de": "Trauma",
         "label_nl": "Trauma",
         "label_rs": "Травма",
         "label_sp": "Trauma",
         "label_pt": "Trauma",
         "label_sw": "Kiwewe",
         "label_ch": "创伤",
         "label_fr": "Traumatisme",
         "label_ar": "الإصابات",
         "value": "Trauma"
      },
      {
         "label_en": "Pediatric oncology",
         "label_de": "Pädiatrische Onkologie",
         "label_nl": "pediatrische oncologie",
         "label_rs": "Детская онкология",
         "label_sp": "oncología pediátrica",
         "label_pt": "oncologia pediátrica",
         "label_sw": "Oncolojia ya watoto",
         "label_ch": "小儿肿瘤",
         "label_fr": "Oncologie pédiatrique",
         "label_ar": "أورام الأطفال",
         "value": "Pediatric_oncology"
      },
      {
         "label_en": "Thoracic Surgery",
         "label_de": "Brustchirurgie",
         "label_nl": "Thoraxchirurgie",
         "label_rs": "Торакальная хирургия",
         "label_sp": "Cirugía Torácica",
         "label_pt": "Cirurgia toráxica",
         "label_sw": "Oparesheni ya kifua",
         "label_ch": "胸外科",
         "label_fr": "Chirurgie thoracique",
         "label_ar": "جراحة الصدر",
         "value": "Thoracic_Surgery"
      },
      {
         "label_en": "Congenital cardiac surgery",
         "label_de": "Angeborene Herzchirurgie",
         "label_nl": "Aangeboren hartoperatie",
         "label_rs": "Кардиохирургия наследственных заболеваний",
         "label_sp": "cirugía cardiaca congénita",
         "label_pt": "cirurgia cardíaca congênita",
         "label_sw": "Oparesheni wa moyo wa kuzaliwa",
         "label_ch": "先天性心脏手术",
         "label_fr": "Chirurgie cardiaque congénitale",
         "label_ar": "جراحة العيوب الخلقية في القلب",
         "value": "Congenital_cardiac_surgery"
      },
      {
         "label_en": "Thoracic surgery-integrated",
         "label_de": "Integrierte Thoraxchirurgie",
         "label_nl": "Thorax-operatie geïntegreerd",
         "label_rs": "Торакальная хирургия: комплексная",
         "label_sp": "cirugía torácica integrada",
         "label_pt": "cirurgia integrada Thoracic",
         "label_sw": "Oparesheni wa kifua jumuishi",
         "label_ch": "胸外科-集成",
         "label_fr": "Chirurgie thoracique intégrée",
         "label_ar": "جراحة الصدر المتكاملة",
         "value": "Thoracic_surgery-integrated"
      },
      {
         "label_en": "Vascular surgery",
         "label_de": "Gefäßchirurgie",
         "label_nl": "Vasculaire chirurgie",
         "label_rs": "Сосудистая хирургия",
         "label_sp": "Cirugía vascular",
         "label_pt": "Cirurgia vascular",
         "label_sw": "Oparesheni wa mishipa",
         "label_ch": "血管手术",
         "label_fr": "Chirurgie vasculaire",
         "label_ar": "جراحة الأوعية الدموية",
         "value": "Vascular_surgery"
      },
      {
         "label_en": "Pediatric urology",
         "label_de": "Kinderurologie",
         "label_nl": "pediatrische urologie",
         "label_rs": "Детская урология",
         "label_sp": "urología pediátrica",
         "label_pt": "urologia pediátrica",
         "label_sw": "Urolojia ya watoto",
         "label_ch": "儿科泌尿科",
         "label_fr": "Urologie pédiatrique",
         "label_ar": "جراحة المسالك البولية للأطفال",
         "value": "Pediatric_urology"
      },
      {
         "label_en": "Urologic oncology",
         "label_de": "Urologische Onkologie",
         "label_nl": "urologische oncologie",
         "label_rs": "Урологическая онкология",
         "label_sp": "oncología urológica",
         "label_pt": "oncologia urológica",
         "label_sw": "Oncolojia ya Urolojia",
         "label_ch": "泌尿肿瘤",
         "label_fr": "Oncologie urologique",
         "label_ar": "أورام المسالك البولية",
         "value": "Urologic_oncology"
      },
      {
         "label_en": "Renal transplant",
         "label_de": "Nierentransplantation",
         "label_nl": "niertransplantatie",
         "label_rs": "Трансплантация почки",
         "label_sp": "trasplante renal",
         "label_pt": "transplante renal",
         "label_sw": "Kupandikiza figo",
         "label_ch": "肾移植",
         "label_fr": "Transplantation rénale",
         "label_ar": "زرع الكلى",
         "value": "Renal_transplant"
      },
      {
         "label_en": "Male infertility",
         "label_de": "Männliche Unfruchtbarkeit",
         "label_nl": "Mannelijke onvruchtbaarheid",
         "label_rs": "Мужское бесплодие",
         "label_sp": "Infertilidad masculina",
         "label_pt": "Infertilidade masculina",
         "label_sw": "Ugumba wa kiume",
         "label_ch": "男性不孕不育",
         "label_fr": "Infertilité masculine",
         "label_ar": "العقم عند الذكور",
         "value": "Male_infertility"
      },
      {
         "label_en": "Calculi",
         "label_de": "Kalküle",
         "label_nl": "calculi",
         "label_rs": "Камни в организмах",
         "label_sp": "cálculos",
         "label_pt": "cálculos",
         "label_sw": "Calculi",
         "label_ch": "结石",
         "label_fr": "Calculs",
         "label_ar": "الحصوات",
         "value": "Calculi"
      },
      {
         "label_en": "Female urology",
         "label_de": "Weibliche Urologie",
         "label_nl": "Vrouw urologie",
         "label_rs": "Женская урология",
         "label_sp": "urología femenina",
         "label_pt": "urologia feminina",
         "label_sw": "Urolojia ya kike",
         "label_ch": "女性泌尿科",
         "label_fr": "Urologie féminine",
         "label_ar": "جراحة المسالك البولية للنساء",
         "value": "Female_urology"
      },
      {
         "label_en": "Neurourology",
         "label_de": "Neurourologie",
         "label_nl": "Neurourology",
         "label_rs": "Нейроурология",
         "label_sp": "Neurourología",
         "label_pt": "Neurourology",
         "label_sw": "Neurourolojia",
         "label_ch": "神经泌尿学",
         "label_fr": "Neurourologie",
         "label_ar": "أعصاب الجهاز البولي والتناسلي",
         "value": "Neurourology"
      }
   ]
}

exports.subspeciality = subspeciality;