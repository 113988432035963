var speciality = {
    "english":[
        {
            "label_en": "ALLERGY & IMMUNOLOGY",
            "label_tr": "ALERJİ VE İMMÜNOLOJİ",
            "label_de": "Allergologie & Immunologie",
            "label_nl": "ALLERGIE & IMMUNOLOGIE",
            "label_rs": "АЛЛЕРГИЯ И ИММУНОЛОГИЯ",
            "label_sp": "ALERGIA E INMUNOLOGIA",
            "label_pt": "ALERGIA E IMUNOLOGIA",
            "label_sw": "ALEJI NA UJAUZITU",
            "label_ch": "过敏和免疫学",
            "value": "ALLERGY_&_IMMUNOLOGY",
            "label_fr": "ALLERGIE ET IMMUNOLOGIE",
            "label_ar": "أمراض الحساسية والمناعة"
        },
        {
            "label_en": "ANESTHESIOLOGY",
            "label_tr": "ANESTEZİYOLOJİ",
            "label_de": "Anästhesie",
            "label_nl": "ANESTHESIOLOGIE",
            "label_rs": "АНЕСТЕЗИОЛОГИЯ",
            "label_sp": "ANESTESIOLOGÍA",
            "label_pt": "ANESTESIOLOGIA",
            "label_sw": "ANESTHESIOLOJIA",
            "label_ch": "麻醉学",
            "value": "ANESTHESIOLOGY",
            "label_fr": "ANESTHÉSIOLOGIE",
            "label_ar": "التخدير"
        },
        {
            "label_en": "DERMATOLOGY",
            "label_tr": "DERMATOLOJİ",
            "label_de": "Dermatologie",
            "label_nl": "DERMATOLOGIE",
            "label_rs": "ДЕРМАТОЛОГИЯ",
            "label_sp": "DERMATOLOGÍA",
            "label_pt": "DERMATOLOGIA",
            "label_sw": "UTIBABU WA NGOZI",
            "label_ch": "皮肤科",
            "value": "DERMATOLOGY",
            "label_fr": "DERMATOLOGIE",
            "label_ar": "الجلدية"
        },
        {
            "label_en": "DIAGNOSTIC RADIOLOGY",
            "label_tr": "TANI RADYOLOJİSİ",
            "label_de": "Diagnostische Radiologie",
            "label_nl": "DIAGNOSTISCHE RADIOLOGIE",
            "label_rs": "ДИАГНОСТИЧЕСКАЯ РАДИОЛОГИЯ",
            "label_sp": "RADIOLOGÍA DIAGNÓSTICA",
            "label_pt": "RADIOLOGIA DE DIAGNÓSTICO",
            "label_sw": "RADIOLOJIA YA UCHAMBUZI",
            "label_ch": "诊断 放射学",
            "value": "DIAGNOSTIC_RADIOLOGY",
            "label_fr": "LA RADIOLOGIE DIAGNOSTIQUE",
            "label_ar": "الأشعة التشخيصية"
        },
        {
            "label_en": "EMERGENCY MEDICINE",
            "label_tr": "ACİL TIP",
            "label_de": "Notfallmedizin",
            "label_nl": "NOODGEVAL MEDICIJN",
            "label_rs": "НЕОТЛОЖНАЯ МЕДИЦИНСКАЯ ПОМОЩЬ",
            "label_sp": "MEDICINA DE EMERGENCIA",
            "label_pt": "MEDICAMENTO DE EMERGÊNCIA",
            "label_sw": "DAWA ZA DHARURA",
            "label_ch": "急诊医学",
            "value": "EMERGENCY_MEDICINE",
            "label_fr": "MÉDECINE D'URGENCE",
            "label_ar": "طب الطوارئ"
        },
        {
            "label_en": "FAMILY MEDICINE",
            "label_tr": "AİLE HEKİMLİĞİ",
            "label_de": "Familienmedizin",
            "label_nl": "FAMILIE MEDICIJN",
            "label_rs": "СЕМЕЙНАЯ МЕДИЦИНА",
            "label_sp": "MEDICINA FAMILIAR",
            "label_pt": "MEDICINA FAMILIAR",
            "label_sw": "DAWA YA FAMILIA",
            "label_ch": "家庭医学",
            "value": "FAMILY_MEDICINE",
            "label_fr": "MÉDECINE FAMILIALE",
            "label_ar": "طب الأسرة"
        },
        {
            "label_en": "INTERNAL MEDICINE",
            "label_tr": "DAHİLİYE",
            "label_de": "Innere Medizin",
            "label_nl": "INWENDIG MEDICIJN",
            "label_rs": "МЕДИЦИНА ВНУТРЕННИХ ОРГАНОВ",
            "label_sp": "MEDICINA INTERNA",
            "label_pt": "MEDICINA INTERNA",
            "label_sw": "TIBA YA UNDANI",
            "label_ch": "内科",
            "value": "INTERNAL_MEDICINE",
            "label_fr": "MÉDECINE INTERNE",
            "label_ar": "الباطنة"
        },
        {
            "label_en": "MEDICAL GENETICS AND GENOMICS",
            "label_tr": "TIBBİ GENETİK VE GENOMİK",
            "label_de": "Medizinische Genetik",
            "label_nl": "MEDISCHE GENETICA EN GENOMIE",
            "label_rs": "МЕДИЦИНСКАЯ ГЕНЕТИКА И ГЕНОМИКА",
            "label_sp": "GENÉTICA MÉDICA Y GENÓMICA",
            "label_pt": "GENÉTICA MÉDICA E GENÔMICA",
            "label_sw": "MAGENENIKI YA KITABU NA MAANA",
            "label_ch": "医学遗传学和基因组学",
            "value": "MEDICAL_GENETICS_AND_GENOMICS",
            "label_fr": "GÉNÉTIQUE ET GÉNOMIQUE MÉDICALES",
            "label_ar": "علم الوراثة الطبية والجينات"
        },
        {
            "label_en": "NEUROLOGY",
            "label_tr": "NÖROLOJİ",
            "label_de": "Neurologie",
            "label_nl": "NEUROLOGIE",
            "label_rs": "НЕВРОЛОГИЯ",
            "label_sp": "NEUROLOGÍA",
            "label_pt": "NEUROLOGIA",
            "label_sw": "UTIBABU WA NEVA",
            "label_ch": "神经学",
            "value": "NEUROLOGY",
            "label_fr": "NEUROLOGIE",
            "label_ar": "قسم الأمراض العصبية"
        },
        {
            "label_en": "NUCLEAR MEDICINE",
            "label_tr": "NÜKLEER TIP",
            "label_de": "Nuklearmedizin",
            "label_nl": "NUCLEAIR MEDICIJN",
            "label_rs": "ЯДЕРНАЯ МЕДИЦИНА",
            "label_sp": "MEDICINA NUCLEAR",
            "label_pt": "MEDICINA NUCLEAR",
            "label_sw": "DAWA YA NYUKU",
            "label_ch": "核医学",
            "value": "NUCLEAR_MEDICINE",
            "label_fr": "MÉDECINE NUCLÉAIRE",
            "label_ar": "الطب النووي"
        },
        {
            "label_en": "OBSTETRICS AND GYNECOLOGY",
            "label_tr": "OBSTETRİK VE JİNEKOLOJİ",
            "label_de": "Geburtshilfe und Gynäkologie",
            "label_nl": "OBSTETRICS AND GYNECOLOGY",
            "label_rs": "АКУШЕРСТВО И ГИНЕКОЛОГИЯ",
            "label_sp": "OBSTETRICIA Y GINECOLOGÍA",
            "label_pt": "OBSTETRÍCIA E GINECOLOGIA",
            "label_sw": "MAGONJWA YA AKINA MAMA NA MAGONJWA YA WANAWAKE",
            "label_ch": "妇产科",
            "value": "OBSTETRICS_AND_GYNECOLOGY",
            "label_fr": "OBSTÉTRIQUE ET GYNÉCOLOGIE",
            "label_ar": "أمراض النساء والتوليد"
        },
        {
            "label_en": "OPHTHALMOLOGY",
            "label_tr": "OFTALMOLOJİ",
            "label_de": "Augenheilkunde",
            "label_nl": "OOGHEELKUNDE",
            "label_rs": "ОФТАЛЬМОЛОГИЯ",
            "label_sp": "OFTALMOLOGÍA",
            "label_pt": "OFTALMOLOGIA",
            "label_sw": "UTIBABU WA JICHO",
            "label_ch": "眼科",
            "value": "OPHTHALMOLOGY",
            "label_fr": "OPHTHALMOLOGIE",
            "label_ar": "طب العيون"
        },
        {
            "label_en": "OTOLANRYNGOLOGY",
            "label_tr": "OTOLARENGOLOJİ",
            "label_de": "Hals-, Nasen- und Ohrenheilkunde (HNO)",
            "label_nl": "KNO-GENEESKUNDE",
            "label_rs": "ОТОЛАРИНГОЛОГИЯ",
            "label_sp": "OTOLARINGOLOGÍA",
            "label_pt": "OTOLARINGOLOGIA",
            "label_sw": "UTIBABU WA SIKIO NA SHINGO",
            "label_ch": "耳鼻喉科",
            "value": "OTOLANRYNGOLOGY",
            "label_fr": "OTORHINOLARYNGOLOGIE",
            "label_ar": "الأنف والأذن والحنجرة"
        },
        {
            "label_en": "PATHOLOGY",
            "label_tr": "PATOLOJİ",
            "label_de": "Pathologie",
            "label_nl": "PATHOLOGIE",
            "label_rs": "ПАТОЛОГИЯ",
            "label_sp": "PATOLOGÍA",
            "label_pt": "PATOLOGIA",
            "label_sw": "PATHOLOJIA",
            "label_ch": "病理",
            "value": "PATHOLOGY",
            "label_fr": "PATHOLOGIE",
            "label_ar": "قسم الباثولوجي (الأمراض)"
        },
        {
            "label_en": "PEDIATRICS",
            "label_tr": "PEDİATRİ",
            "label_de": "Pädiatrie",
            "label_nl": "KINDERGENEESKUNDE",
            "label_rs": "ПЕДИАТРИЯ",
            "label_sp": "PEDIATRÍA",
            "label_pt": "PEDIATRIA",
            "label_sw": "UTIBABU WA UGONJWA WA WATOTO",
            "label_ch": "儿科",
            "value": "PEDIATRICS",
            "label_fr": "PÉDIATRIE",
            "label_ar": "طب الأطفال"
        },
        {
            "label_en": "PHYSICAL MEDICINE & REHABILITATION",
            "label_tr": "FİZİKSEL TIP & REHABİLİTASYON",
            "label_de": "Physikalische Medizin & Rehabilitation",
            "label_nl": "FYSIEKE GENEESKUNDE & REVALIDATIE",
            "label_rs": "ФИЗИЧЕСКАЯ МЕДИЦИНА И РЕАБИЛИТАЦИЯ",
            "label_sp": "MEDICINA FISICA Y REHABILITACION",
            "label_pt": "MEDICINA FÍSICA E REABILITAÇÃO",
            "label_sw": "DAWA YA KIMWILI NA KUKABIDHI",
            "label_ch": "物理医学与康复",
            "value": "PHYSICAL_MEDICINE_&_REHABILITATION",
            "label_fr": "MÉDECINE PHYSIQUE ET RÉADAPTATION",
            "label_ar": "العلاج الطبيعي وإعادة التأهيل"
        },
        {
            "label_en": "PREVENTIVE MEDICINE",
            "label_tr": "ÖNLEYİCİ İLAÇ",
            "label_de": "Präventivmedizin",
            "label_nl": "PREVENTIEVE GENEESKUNDE",
            "label_rs": "ПРОФИЛАКТИЧЕСКАЯ МЕДИЦИНА",
            "label_sp": "MEDICINA PREVENTIVA",
            "label_pt": "MEDICINA PREVENTIVA",
            "label_sw": "DAWA YA KUZUIA",
            "label_ch": "预防医学",
            "value": "PREVENTIVE_MEDICINE",
            "label_fr": "MÉDECINE PRÉVENTIVE",
            "label_ar": "الطب الوقائي"
        },
        {
            "label_en": "PSYCHIATRY",
            "label_tr": "PSİKİYATRİ",
            "label_de": "Psychiatrie",
            "label_nl": "PSYCHIATRIE",
            "label_rs": "ПСИХИАТРИЯ",
            "label_sp": "PSIQUIATRÍA",
            "label_pt": "PSIQUIATRIA",
            "label_sw": "KISAIKOLOJIA",
            "label_ch": "精神病学",
            "value": "PSYCHIATRY",
            "label_fr": "PSYCHIATRIE",
            "label_ar": "الطب النفسي"
        },
        {
            "label_en": "RADIATION",
            "label_tr": "RADYASYON",
            "label_de": "Nuklearmedizin",
            "label_nl": "RADIATIE",
            "label_rs": "РАДИОЛОГИЯ",
            "label_sp": "RADIACIÓN",
            "label_pt": "RADIAÇÃO",
            "label_sw": "MIONZI",
            "label_ch": "辐射",
            "value": "RADIATION",
            "label_fr": "RAYONNEMENT",
            "label_ar": "الأشعة"
        },
        {
            "label_en": "ONCOLOGY",
            "label_tr": "ONKOLOJİ",
            "label_de": "Hämatoonkologie",
            "label_nl": "ONCOLOGIE",
            "label_rs": "ONCOLOGY",
            "label_sp": "ONCOLOGÍA",
            "label_pt": "ONCOLOGIA",
            "label_sw": "ONCOLOJIA",
            "label_ch": "肿瘤学",
            "value": "ONCOLOGY",
            "label_fr": "ONCOLOGIE",
            "label_ar": "قسم الأورام"
        },
        {
            "label_en": "SURGERY",
            "label_tr": "AMELİYAT",
            "label_de": "Chirurgie",
            "label_nl": "CHIRURGIE",
            "label_rs": "ХИРУРГИЯ",
            "label_sp": "CIRUGÍA",
            "label_pt": "CIRURGIA",
            "label_sw": "OPARESHENI",
            "label_ch": "手术",
            "value": "SURGERY",
            "label_fr": "CHIRURGIE",
            "label_ar": "قسم الجراحة"
        },
        {
            "label_en": "UROLOGY",
            "label_tr": "ÜROLOJİ",
            "label_de": "Urologie",
            "label_nl": "UROLOGIE",
            "label_rs": "УРОЛОГИЯ",
            "label_sp": "UROLOGÍA",
            "label_pt": "UROLOGIA",
            "label_sw": "UROLOGIA",
            "label_ch": "手术",
            "value": "UROLOGY",
            "label_fr": "UROLOGIE",
            "label_ar": "المسالك البولية"
        }
    ]
}
exports.speciality = speciality;

