import sitedata from "sitedata";

const path = sitedata.data.path;

export const APIs = {
  getfeedbackfordoctor: path + "/vchat/getfeedbackfordoctor",
};

export const APIs1 = {
  cometUserList: path + "/cometUserList",
};

export const APIs2 = {
  linktime: path + "/vactive/Linktime"
};

export const APIs3 = {
  joinmeeting: path + "/vactive/joinmeeting/"
}
// export default APIs