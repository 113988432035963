
import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';

import PrivateRoute from './PrivateRoute';

import * as actions from './store/action';
import { COMETCHAT_CONSTANTS } from './consts';
import { CometChatUnified } from './react-chat-ui-kit/CometChat';
import { CometChat } from '@cometchat-pro/chat'; 

const history = createBrowserHistory();

class App extends React.Component {
  state = {
    isLoggedin: false
  }

  componentDidMount() {
    console.log('ii')
    console.log('this.props.Uid',this.props.Uid)
    console.log('COMETCHAT_CONSTANTS.AUTH_KEY',COMETCHAT_CONSTANTS.AUTH_KEY)
    CometChat.getLoggedinUser().then(user => {
    if (user) {
      console.log('user',user)
      this.setState({ isLoggedin: true });
      if (this.props.getLoggedinUser) {
        console.log("this call")
        this.props.getLoggedinUser();
      } else {
        console.log('getLoggedinUser method is not defined in props');
      }
    } else {
      console.log("this call1")

      setTimeout(() => {
        this.setState({ isLoggedin: true });
      }, 3000);
      if (this.props.onLogin) {
        console.log("this call2")

        this.props.onLogin(this.props.Uid, COMETCHAT_CONSTANTS.AUTH_KEY);
      } else {
        console.log('onLogin method is not defined in props');
      }
    }
  })
  .catch(error => {
    console.log('Error:', error);
    if (this.props.onLogin) {
      this.props.onLogin(this.props.Uid, COMETCHAT_CONSTANTS.AUTH_KEY);
    } else {
      console.log('onLogin method is not defined in props');
    }
  });

  //   CometChat.getLoggedinUser().then(user => {
  //     if(user) {
  //       this.setState({isLoggedin : true})
  //   this.props.getLoggedinUser();

        
  //     } else {
  //       setTimeout(()=>{this.setState({isLoggedin : true})},3000)
  //       this.props.onLogin(this.props.Uid, COMETCHAT_CONSTANTS.AUTH_KEY);
  //     }
  // }).catch(error => {
  //   console.log('>>>',error)
  //   this.props.onLogin(this.props.Uid, COMETCHAT_CONSTANTS.AUTH_KEY);
  // });
    
  }

  render() {
    console.log('this.state.isLoggedin',this.state.isLoggedin,'-',this.props.Userlist)
    console.log('this.props.Uid',this.props.Uid,'-',this.props.lan)
    
    return (
  
      <div>
     
        <PrivateRoute Userlist={this.props.Userlist} Uid={this.props.Uid} lan={'IN'} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.loading,
    error: state.error,
    isLoggedIn: state.isLoggedIn
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLogin: ( uid, authKey ) => dispatch( actions.auth( uid, authKey ) ),
    getLoggedinUser: () => dispatch(actions.authCheckState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);