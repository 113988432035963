export const Currency = [
    { "value": "US", "label": "USD (US-Dollar)" },
    { "value": "AT", "label": "EUR (Euro)" },
    { "value": "UAE", "label": "AED (UAE-Dirham)" },
    { "value": "UK", "label": "GBP (British-Pound)" },
    { "value": "TH", "label": "THB (Thai-Bhat)" },
    { "value": "PH", "label": "PHP (Philippine Peso)" },
    { "value": "CH", "label": "CNY (Chinese Yuan)" },
    { "value": "RS", "label": "RUB (Russian Ruble)" },
    { "value": "BT", "label": "BTC (Bitcoin)" },
    { "value": "Aimedis", "label": "AIMX (Aimedis)" },
    { "value": "ETH", "label": "ETH (Ethereum)" },
    { "value": "KE", "label": "KES (Kenyan Shilling)" },
    { "value": "ZW", "label": "ZWL (Zimbabwe Dollar)"},
    { "value": "MA", "label": "MAD (Moroccan Dirham)"},
    { "value": "TR", "label": "TRY (Turkish Lira)"},
]
