var data = {
  path1: "https://api.innorade.com/api/v5",
  
  path: "https://api.innorade.com/api/v5",
  // path1: "http://localhost:5001/api/v5",
  
  // path: "http://localhost:5001/api/v5",
  

 
  dowload_link: 'https://api.innorade.com/api/v5',
};
exports.data = data;
